import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
import Header from "../../Header/Header";
import './stuti.css'

function Stuti() {

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      <Header />
      {loading ? (
       <div className="loader-container" >
       <div className="loader-icon">
           <img src='/assets/img/logo2.png' alt="" />
           <img src="/assets/img/loading5.gif" alt="" />
       </div>
   </div>
      ) : (
        <>
          <section className="innerPageHeader">
            <div className="container" >
              <div className='px-5'>
                <h2 className='fs-white m-0'>Stuti</h2>
                <ol className="breadcrumb fs-6">
                  <li className="breadcrumb-item">
                    <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                      <span className='backpath'>Home </span>
                    </Link>

                  </li>
                  <li className="breadcrumb-item active" aria-current="page">STUTI</li>
                </ol>
              </div>
            </div>
          </section>

          <div className="stuti">
            <div className="starting-space">
              <div className="container ">
                <h3 className="text-center">શ્રી ઘેલા સોમનાથ મહાદેવની સ્તુતિ</h3>
                <div className=" d-flex justify-content-center align-items-center mt-3">
                  <div className="d-flex flex-column gap-3">
                    <p>
                      <strong>।૧। </strong>
                      ઝાડીનું શુભ જુથ શૈલ શીખરો 		ઉન્મત્ત ગંગા વહે,<br />
                      જેની પાસ ખાસ નિવાસ કરવા 		મોટા મુનિવરો પણ ચાહે,<br />
                      તેઠામે વસીયા સદાશીવ પ્રભુ		સદ્ભાગ્ય ભુમિતણું,<br />
                      તે ઘેલાશીવ સોમનાથ તમને ... 		પાદારવિંદે પડું..
                    </p>
                    <p>
                      <strong>।૨। </strong>
                      પુર્વે પાટણમાં હતા પ્રભુ તમે 		જયાં ઉદધિ ઉછળે,<br />
                      દેવી મિનળની અપુર્વ મનસા 		લાવી વિભુ આ સ્થળે,<br />
                      પંચાળે પ્રગટ થયા તે દેશનું 		પુન્ય જાણ્યું હું વડું,<br />
                      તે ઘેલાશીવ સોમનાથ તમને ... 		પાદારવિંદે પડું..
                    </p>
                    <p>
                      <strong>।૩। </strong>
                      તારા પાયથકી રૂડી પ્રસરતી 		ઘેલો સરિતા વિભુ,<br />
                      જેમાં સ્નાન કર્યા થકી 			દુર્ગપુરીમાં શ્રીજી ગણાયા પ્રભુ,<br />
                      ઘેલો નામ મટી ગયું તુંજ વડે		ગંગા ગણાયું ખરૂં,<br />
                      તે ઘેલાશીવ સોમનાથ તમને ... 		પાદારવિંદે પડું..
                    </p>
                    <p>
                      <strong>।૪। </strong>
                      તારા ધામ વિચેક્ષ, વિચગ્રહને		પાછો સ્વદેશે જતા,<br />
                      મુંઝાયો મહમદ માર્ગ નવમળ્યો 	ચીન્તા અરણ્યે ચાલતાં,<br />
                      તૃષાથી યવનો મર્યા			વારિબિન્દુ નવ મળ્યું,<br />
                      તે ઘેલાશીવ સોમનાથ તમને ... 		પાદારવિંદે પડું..
                    </p>
                    <p>

                      <strong>।૫। </strong>
                      શ્રીજી શિક્ષાપત્રીમાં કહી ગયા 		શેવો સ્વયંભુ શિવ,<br />
                      તોએ મનધરી તુંજને નવ નમે 		એ ખેદકારી બહુ,<br />
                      તારાથી વિમુખ જનનું 			મુખ જાણું હું ૫ડું,<br />
                      તે ઘેલાશીવ સોમનાથ તમને ... 		પાદારવિંદે પડું..
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <FooterPage />
        </div>
          </>
            )}
    </div>
  )
}

export default Stuti