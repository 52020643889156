import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import './pooja.css';
import Header from '../../Header/Header';
import FooterPage from '../../Footer/FooterPage';
import { get_puja } from "../../../common/serverUrl";
import fetchData from "../../../common/fetchData";

const Puja = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [poojaData, setPoojaData] = useState();
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        getpuja();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getpuja = async () => {
        const puja = await fetchData(
            "post",
            get_puja, "",
        );
        // console.log(puja);
        if (puja.code === "1") {
            // console.log(puja.data);
            setPoojaData(puja.data);
        }
        else {
            console.log("not found");
        }
    };

    const handlePoojaBooking = async (id) => {
        navigate(`/pujabooking/${id}`);
    };

    return (
        <>
            <Header />
            {loading ? (
                <div className="loader-container" >
                <div className="loader-icon">
                    <img src='/assets/img/logo2.png' alt="" />
                    <img src="/assets/img/loading5.gif" alt="" />
                </div>
            </div>
            ) : (
                <>
                    <section className="innerPageHeader">
                        <div className="container" >
                            <div className='px-5'>
                                <h2 className='fs-white m-0 font'>Puja</h2>
                                <ol className="breadcrumb fs-6">
                                    <li className="breadcrumb-item">
                                        <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                                            <span className='backpath'>Home </span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Puja</li>
                                </ol>
                            </div>
                        </div>
                    </section>

                    <section >
                        <div className="row puja-space">
                            <div className="col-lg-12">
                                {poojaData && poojaData?.length > 0 ? (
                                    <div className="row">
                                        {poojaData.map((item, index) =>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
                                                <div className="card puja-img mb-2" style={{ cursor: "pointer" }}>
                                                    <div className="puja-img">
                                                        <img style={{ cursor: "pointer" }} src={"https://ghelasomnath.org/images/" + item?.image} className="card-img-top" alt="ghelasomnath-puja" />
                                                        <div className='overlay'>
                                                            <button className="puja-view-btn" onClick={() => handlePoojaBooking(item.id)}>Book</button>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <h5 onClick={() => handlePoojaBooking(item.id)}>{item?.name}</h5>
                                                        <p className="mt-2"> <span style={{fontWeight:'600'}}>Price</span> &nbsp;₹{item?.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <h2  >Data Not Found!{poojaData}</h2>
                                )}
                            </div>

                          
                        </div>
                    </section>
                    <FooterPage />
                </>
            )}
        </>
    )
}

export default Puja
