import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
import { add_puja_booking, get_puja, payment, } from "../../../common/serverUrl";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import fetchData from "../../../common/fetchData";
import Toast from "react-bootstrap/Toast";
import Header from '../../Header/Header';
import axios from 'axios';
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import { Country, State, City } from 'country-state-city';
import Select from "react-select";

const PujaBooking = () => {

    let { id } = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [Name, setName] = useState("");
    const [Phone, setPhone] = useState("");
    const [Email, setEmail] = useState("");
    const [PujaCategory, setPujaCategory] = useState("");
    const [PujaDate, setPujaDate] = useState("");
    const [Person, setPerson] = useState("");
    const [Amount, setAmount] = useState(false);
    const [isError, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [Address, setAddress] = useState("");
    const [Zip, setZip] = useState("");
    const [Image, setImage] = useState(null);

    const phoneNumber = event => {
		const limit = 10;
		setPhone(event.target.value.slice(0, limit));
	  };

    var asyncLoop = require('node-async-loop');
    function handleImage(e) {
        const selectedFIles = [];
        if (e.target.files && e.target.files[0]) {
            if (e.target.files[0].size > 1 * 1000 * 1024) {
                Swal.fire({
                    position: 'top-center',
                    icon: 'error',
                    title: "File size not allowed to Graterthan 1MB",
                    toast: true,
                    showConfirmButton: false,
                    timer: 4000
                })
                return false;
            }
        }
        const targetFiles = e.target.files;
		const targetFilesObject = [...targetFiles]
		targetFilesObject.map((file) => {
			return selectedFIles.push(file)
		})
		setImage(selectedFIles);
    }


    // console.log(Image)
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        getPuja();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
    const getPuja = async () => {
        const Puja = await fetchData(
            "post",
            get_puja, "",
            {
                pooja_id: id
            }
        );
        // console.log(Puja);
        if (Puja.code === "1") {
            // console.log(Puja.data[0]);
            setAmount(Puja.data[0].price);
            setPujaCategory(Puja.data[0].name);
            // setSelectedTabData(Puja.data)
        }
        else {
            console.log("not found");
        }
    };
    const onSubmit = async (event) => {
        event.preventDefault();
        
        var imageArray = [];
        if (Image == null) {
            Swal.fire({
                position: 'top-center',
                icon: 'warning',
                title: "Please Upload Your Id Proof",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        } else {
            asyncLoop(Image, function (item, next) {
                const formData = new FormData();
                formData.append("personal_id_image", item);
                axios.post("https://ghelasomnath.org:8081/api/v1/user/imageUpload", formData).then((res) => {
                    imageArray = res.data.data
                    next();
                })
            }, function () {
                
               fetchData(
                    "post",
                    add_puja_booking,
                    " ",
                    {
                        name: Name,
                        phone: Phone,
                        pooja_category: PujaCategory,
                        pooja_date: PujaDate,
                        payment_amount: Amount,
                        payment_status: "Pending",
                        email: Email,
                        id: id,
                        person: Person,
                        city: selectedCity.name,
                        state: selectedState.name,
                        country: selectedCountry.name,
                        address: Address,
                        zip: Zip,
                        personal_id_image: imageArray
                    }
                ).then((response) => {
					if (response.code === "1") {
                   // console.log("pooja success",response.data)
                    fetchData(
                        "get",
                        payment,
                        " ",
                        {
                        }
                    ).then((responsee) => {
                        const formBlob = new Blob([responsee], { type: 'text/html' }); // Adjust the MIME type according to your form data
                        // Create a URL for the Blob object
                        const formUrl = URL.createObjectURL(formBlob);
                        // Open the form in a new tab
                        window.open(formUrl, '_self');
                        // console.log("--------->>", responsee);
                      });
                    
                }
				});
            
            })
        }
    }
 
    return (
        <div>
            <Header />
            {loading ? (
                <div className="loader-container" >
                    <div className="loader-icon">
                        <img src='/assets/img/logo2.png' alt="" />
                        <img src="/assets/img/loading5.gif" alt="" />
                    </div>
                </div>
            ) : (
                <>
                    <section className="innerPageHeader">
                        <div className="container" >
                            <div className='px-5'>
                                <h1 className='fs-white m-0 font'>Puja Booking</h1>
                                <ol className="breadcrumb fs-6">
                                    <li className="breadcrumb-item">
                                        <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                                            <span className='backpath'>Home </span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to='/puja' className={`${location.pathname === "/puja" ? "btn-link" : ""}`}>
                                            <span className='backpath'>Puja</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">PujaBooking</li>
                                </ol>
                            </div>
                        </div>
                    </section>

                    {isError && message !== "" && (
                        <ToastContainer className="p-3" position="top-center">
                            <Toast>
                                <Toast.Header>
                                    <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded me-2"
                                        alt=""
                                    />
                                    <strong className="me-auto" style={{ color: "red" }}>
                                        {isError ? "Error" : "Success"}
                                    </strong>
                                </Toast.Header>
                                <Toast.Body>{message}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    )}
                    <div style={{ backgroundImage: "url(https://ghelasomnath.org/assets/img/events/bg-grey.png)" }}>
                        <div className="container">
                            {
                                <form onSubmit={onSubmit}>
                                    <div className="row pujabooking">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <i className="far fa-user"></i>
                                                <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} required value={Name} placeholder="Name" name="name" />
                                            </div>
                                        </div>
                                      
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <input type="date" className="form-control" min={disablePastDate()} onChange={(e) => setPujaDate(e.target.value)} required placeholder="Puja Date" name="Date" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <i className="far fa-users"></i>
                                                <input type="number" className="form-control" onChange={(e) => setPerson(e.target.value)} required placeholder="Person" name="name" />
                                            </div>

                                            <div className="form-group">
                                                <i className="far fa-phone"></i>
                                                <input type="number" className="form-control" onChange={phoneNumber} required
                                                    value={Phone} placeholder="Phone" name="phone" />
                                            </div>

                                            <div className="form-group">
                                                <i className="far fa-envelope"></i>
                                                <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)}
                                                    required name="email" placeholder="Please enter email" />
                                            </div>

                                            <div className="form-group">
                                                <i className="far fa-pencil"></i>
                                                <input type="text" readonly="readonly" className="form-control" onChange={(e) => setPujaCategory(e.target.value)}
                                                    required value={PujaCategory} name="Pujacategory" style={{ backgroundColor: "white" }} />
                                            </div>

                                            <div className="form-group">
                                                <i className="far fa-image" style={{ paddingTop: "2rem" }}></i>
                                                <label for="formFileLg" class="form-label" style={{ paddingLeft: "1rem" }}>Upload Your Id Proof <span style={{ color: "red", fontSize: "12px" }}>(max file size 1 MB.)</span></label>
                                                <input className="form-control form-control-lg image" id="formFileLg" type="file" placeholder="Add File" rows="4" style={{ backgroundColor: "white", paddingLeft: "2rem", paddingTop: "1rem" }} accept="image/*" onChange={handleImage} required-/>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <Select
                                                    className="form-control"
                                                    placeholder="Select Country"
                                                    options={Country.getAllCountries()}
                                                    getOptionLabel={(options) => {
                                                        return options["name"];
                                                    }}
                                                    getOptionValue={(options) => {
                                                        return options["name"];
                                                    }}
                                                    value={selectedCountry}
                                                    onChange={(item) => {
                                                        setSelectedCountry(item);
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <Select
                                                    className="form-control"
                                                    placeholder="Select State"
                                                    options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                                    getOptionLabel={(options) => {
                                                        return options["name"];
                                                    }}
                                                    getOptionValue={(options) => {
                                                        return options["name"];
                                                    }}
                                                    value={selectedState}
                                                    onChange={(item) => {
                                                        setSelectedState(item);
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <Select className="form-control"
                                                    placeholder="Select City"
                                                    options={City.getCitiesOfState(
                                                        selectedState?.countryCode,
                                                        selectedState?.isoCode
                                                    )}
                                                    getOptionLabel={(options) => {
                                                        return options["name"];
                                                    }}
                                                    getOptionValue={(options) => {
                                                        return options["name"];
                                                    }}
                                                    value={selectedCity}
                                                    onChange={(item) => {
                                                        setSelectedCity(item);
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <i className="far fa-file"></i>
                                                <input type="text" name="zip" className="form-control" onChange={(e) => setZip(e.target.value)} required placeholder="Pincode" />
                                            </div>

                                            <div className="form-group">
                                                <i className="far fa-home"></i>
                                                <textarea type="text" name="address" className="form-control" placeholder="Address" rows="4" onChange={(e) => setAddress(e.target.value)} value={Address} />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <i className="far  fa-rupee-sign"></i>
                                                <input type="number" readonly="readonly" name="amount" className="form-control" onChange={(e) => setAmount(e.target.value)}
                                                    required value={Amount} placeholder="Amount" rows="4" style={{ backgroundColor: "white" }} />
                                            </div>
                                        </div>


                                        <div className="d-flex justify-content-center gap-2">

                                            <button type="submit" className="common-btn" name="button"> Booking
                                                <PiArrowFatLinesRightFill className='fs-4 ms-1' />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>

                    <FooterPage />
                </>
            )
            }
        </div >
    )
}

export default PujaBooking
