import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { CgClose } from "react-icons/cg";
import { Button } from 'react-bootstrap';

const QrCode = () => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
      setOpen(true);
  }, []);


  return (
    <>
        <div>
          <Modal
            onClose={handleClose}
            open={open}
            style={{
              position: 'absolute',
              top: '10%',
              display: 'flex',
              justifyContent: 'center',
              height: 'auto',
              width: 'fit-content',
              margin: 'auto',
            }}
          >
            <div>
              <img src="/assets/img/donation/qrcode.jpg" style={{ height: '100vh', width: '100%' }} alt="" />

              <Button onClick={handleClose} variant='contained' style={{
                position: 'absolute',
                right: '-0.0rem',
                fontSize: '17px',
                border: '1px solid white',
                borderRadius: '50%',
                WebkitBorderRadius: '50%',
                backgroundColor: "rgb(72, 11, 11)",
                color: 'white',
                padding: '0px 5px'
              }}><CgClose /></Button>

            </div>
          </Modal>
        </div>
    </>
  )
}

export default QrCode
