import React, { useEffect, useState } from "react";
import "./liveDarshan.css";
import { Link, useLocation } from "react-router-dom";
import Header from "../../Header/Header";
import FooterPage from "../../Footer/FooterPage";
import Snowfall from 'react-snowfall';

const snowflake1 = document.createElement('img');
snowflake1.src = '/assets/img/liveDarshan/billy-1.png';
const snowflake2 = document.createElement('img');
snowflake2.src = '/assets/img/liveDarshan/billy-2.png';
const snowflake3 = document.createElement('img');
snowflake3.src = '/assets/img/liveDarshan/billy-3.png';
const snowflake4 = document.createElement('img');
snowflake4.src = '/assets/img/liveDarshan/rose-1.png';
const snowflake5 = document.createElement('img');
snowflake5.src = '/assets/img/liveDarshan/rose-2.png';
const snowflake6 = document.createElement('img');
snowflake6.src = '/assets/img/liveDarshan/rose-3.png';

const billiImgs = [snowflake1, snowflake2, snowflake3];
const roseImgs = [snowflake4, snowflake5, snowflake6];
const LiveDarshan = () => {
    const location = useLocation();
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setLoading(true);
        setToggleState(index);
        setLoading(false);
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <div className="snow" style={{ position: 'relative' }}>

            <Header />
            {loading ? (
                <div className="loader-container">
                    <div className="loader-icon">
                        <img src="/assets/img/logo2.png" alt="" />
                        <img src="/assets/img/loading5.gif" alt="" />
                    </div>
                </div>
            ) : (
                <>
                {  toggleState % 2 != 0  &&
                    <Snowfall
                        snowflakeCount={15}
                        images={billiImgs}
                        radius={[25, 50]}
                        speed={[0, 0.5]}

                        style={{
                            position: 'fixed',
                            width: '100vw',
                            height: '100vh',
                            zIndex: '9999'
                        }}

                    ></Snowfall>
                }
                {  toggleState % 2 == 0 &&
                    <Snowfall
                        snowflakeCount={15}
                        images={roseImgs}
                        radius={[20, 35]}
                        speed={[0, 0.5]}

                        style={{
                            position: 'fixed',
                            width: '100vw',
                            height: '100vh',
                            zIndex: '9999'
                        }}

                    ></Snowfall>
                }


                    <section className="innerPageHeader">
                        <div className="container">
                            <div className="px-5">
                                <h2 className="fs-white m-0 font">Live Darshan</h2>
                                <ol className="breadcrumb fs-6">
                                    <li className="breadcrumb-item">
                                        <Link
                                            to="/"
                                            className={`${location.pathname === "/" ? "btn-link" : ""
                                                }`}
                                        >
                                            <span className="backpath">Home </span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        LiveDarshan
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </section>

                    <section className=" live-darshan">
                        <h2 className="title m-0" style={{ padding: "20px" }}>
                            શ્રી ઘેલા સોમનાથ મહાદેવ{" "}
                        </h2>
                        <div className="row live">
                            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 live-tab d-flex flex-column gap-2">
                                <div className="live-darshan-img">
                                    <Link
                                        className={
                                            toggleState === 1 ? "tabs-live active-tabs" : "tabs-live"
                                        }
                                        onClick={() => toggleTab(1)}
                                    >
                                        <img
                                            className="img-fluid" alt="ghelaSomnath-mahadev"
                                            src="https://ghelasomnath.org/assets/img/about/ghelaSomnath-mahadev.jpg"
                                        />
                                    </Link>
                                    <p>Ghela Somnath</p>
                                </div>

                                <div className="live-darshan-img">
                                    <Link
                                        className={
                                            toggleState === 2 ? "tabs-live active-tabs" : "tabs-live"
                                        }
                                        onClick={() => toggleTab(2)}
                                    >
                                        <img
                                            className="img-fluid" alt="Ghela-River"
                                            src="https://ghelasomnath.org/assets/img/dharma-talks/Ghela-River.jpg"
                                        />
                                    </Link>
                                    <p>Ghela River</p>
                                </div>

                                <div className="live-darshan-img">
                                    <Link
                                        className={
                                            toggleState === 3 ? "tabs-live active-tabs" : "tabs-live"
                                        }
                                        onClick={() => toggleTab(3)}
                                    >
                                        <img
                                            className="img-fluid" alt="ghelasomnath-temple"
                                            src="https://ghelasomnath.org/assets/img/about/temple2.jpg"
                                        />
                                    </Link>
                                    <p>Ghela Somnath Dada</p>
                                </div>

                                <div className="live-darshan-img">
                                    <Link
                                        className={
                                            toggleState === 4 ? "tabs-live active-tabs" : "tabs-live"
                                        }
                                        onClick={() => toggleTab(4)}
                                    >
                                        <img
                                            className="img-fluid" alt="ghela_somnath_shivratri"
                                            src="https://ghelasomnath.org/assets/img/live-darshan/ghela_somnath_shivratri.jpg"
                                        />
                                    </Link>
                                    <p>President Kovind visit</p>
                                </div>
                            </div>
                            <div className="content-tabs col-xl-10 col-lg-9 col-md-9 col-sm-12">
                                <div
                                    className={
                                        toggleState === 1
                                            ? "live-content  active-content"
                                            : "live-content"
                                    }
                                >
                                    <iframe
                                        className="live-darshan-video col-12"
                                        src="https://www.youtube.com/embed/BFZHPK2oiy0"
                                        title="YouTube video player"
                                        // frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    ></iframe>
                                </div>
                                <div
                                    className={
                                        toggleState === 2
                                            ? "live-content  active-content"
                                            : "live-content"
                                    }
                                >
                                    <iframe
                                        className="live-darshan-video col-12"
                                        src="https://www.youtube.com/embed/oTeFgF5WvHo"
                                        title="YouTube video player"
                                        // frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    ></iframe>
                                </div>
                                <div
                                    className={
                                        toggleState === 3
                                            ? "live-content  active-content"
                                            : "live-content"
                                    }
                                >
                                    <iframe
                                        className="live-darshan-video col-12"
                                        src="https://www.youtube.com/embed/4Sy66b5XR0s"
                                        title="YouTube video player"
                                        // frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    ></iframe>
                                </div>
                                <div
                                    className={
                                        toggleState === 4
                                            ? "live-content  active-content"
                                            : "live-content"
                                    }
                                >
                                    <iframe
                                        className="live-darshan-video col-12"
                                        src="https://www.youtube.com/embed/MWU9okkGGnE"
                                        title="YouTube video player"
                                        // frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    ></iframe>
                                </div>
                            </div>
                        </div>


                    </section>
                    <FooterPage />
                    
                </>
            )}
        </div>
    );
};

export default LiveDarshan;
