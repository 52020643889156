// import React from 'react'
// import './CoreVision.css'

// const CoreVision = () => {
//     return (
//         <div style={{ padding: "2vmax 7vmax",background:'url(https://ghelasomnath.org/assets/img/dharma-talks/bg-grey.png)' }}>
//             <div className='container'>
//                 <div className="row">
//                     <div className="col-lg-7  align-self-center core-details">
//                         <h2>શ્રી ઘેલા સોમનાથ <br /> મંદિરની વિશેષતાઓ</h2>

//                         <p className='my-4 text-muted' style={{ fontSize: "13px",textAlign:'justify' }}>શ્રી ઘેલા સોમનાથ મંદિરના વિશાળ પટાંગણમાં વિશાળ ગૌશાળા આવેલી છે. તેમાં નાના-મોટા આશરે ૧ર૦ થી વધારે ગાયો રાખવામાં આવેલ છે. અને આ ગાયોનું દુધ મંદિરમાં થતા અભિષેક માટે ઉપયોગમાં લેવાય વાપરવામાં આવે છે, અને જે દુધ વધે તે યાત્રીકો માટે ચા તથા દુધ છાસમાં વા૫રવામાં આવે છે.આ મંદિરમાં સને ૨૦૦૫-૦૬ માં સરકારશ્રીએ પ્રવાસન વર્ષમાં સમાવેશ કરતા છેલ્લા ત્રણથી ચાર વર્ષમાં સરકારશ્રીના સહયોગથી અને સરકારી ગ્રાન્ટમાંથી તેમજ લોકમેળા સમિતિ-રાજકોટ અને લોક ભાગીદારી, દાતાઓના દાનથી આ જગ્યામાં ઘણો જ વિકાસ કરવામાં આવેલ છે. અને આ સમય દરમ્યાન મંદિરમાં આશરે પાંચ થી છ કરોડનો અંદાજીત ખર્ચ કરવામાં આવ્યો છે. યાત્રીકો અને ભાવિકો માટેના આરામ અને આનંદ માટે બાગ બગીચાઓ બનાવામાં આવ્યા છે.</p>

//                         <div className="row">
//                             <div className="col-lg-6 col-md-6 core-bg-img mb-1">
//                                 <div className="video">
//                                     <img src="https://ghelasomnath.org/assets/img/gaushala.jpg" alt="" className='' />
//                                 </div>
//                             </div>

//                             <div className="col-lg-6 col-md-6">
//                                 <div className="video">
//                                     <img src="https://ghelasomnath.org/assets/img/gaushala2.jpg" alt="" className='' />
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                     <div className="col-lg-5 core-img">
//                         <img src="https://ghelasomnath.org/assets/img/about/temple1.jpg" alt="" className='cimg' />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default CoreVision

import React from 'react';
import './CoreVision.css';

const CoreVision = () => {
    return (
        <div style={{ padding: "2vmax 7vmax", background: 'url(https://ghelasomnath.org/assets/img/dharma-talks/bg-grey.png)' }}>
            <div className='container'>
                <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 iframe-video">
                        <iframe
                         width="100%"
                            height="650"
                            className="responsive-iframe"
                            src="https://www.youtube.com/embed/4YzoUsv7nbU"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className="col-lg-6 order-1  order-lg-2 align-self-center core-details">
                        <h2 className='mb-2'>Ghela Somnath Temple:</h2>
                        <h2 className='droneview text-center'> Drone View</h2>
                    </div>
                </div>
                {/* Second Row */}
                <div className="row">
                    <div className="col-lg-7 align-self-center core-details">
                        <h2>શ્રી ઘેલા સોમનાથ <br /> મંદિરની વિશેષતાઓ</h2>
                        <p className='my-4 text-muted' style={{ fontSize: "13px", textAlign: 'justify' }}>
                            શ્રી ઘેલા સોમનાથ મંદિરના વિશાળ પટાંગણમાં વિશાળ ગૌશાળા આવેલી છે. તેમાં નાના-મોટા આશરે ૧ર૦ થી વધારે ગાયો રાખવામાં આવેલ છે.
                            {/* Remaining content */}
                        </p>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 core-bg-img mb-1">
                                <div className="video">
                                    <img src="https://ghelasomnath.org/assets/img/gaushala.jpg" alt="" className='' />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="video">
                                    <img src="https://ghelasomnath.org/assets/img/gaushala2.jpg" alt="" className='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 core-img">
                        <img src="https://ghelasomnath.org/assets/img/about/temple1.jpg" alt="" className='cimg' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoreVision;