import React, { useEffect, useState } from "react";
import { get_clientUpcomingEvnets, get_three_events_news_activities } from "../../../common/serverUrl";
import { Link, useNavigate } from "react-router-dom";
import fetchData from "../../../common/fetchData";
import './EventNewsActivity.css'

const EventNewsActivity = () => {
    const navigate = useNavigate();
    const [toggleState, setToggleState] = useState(1);
    const [upcomingevents, setUpcomingevents] = useState([]);
    const [threeEventNewsActivitiesData, setThreeEventNewsActivitiesData] = useState([]);
    const currentDate = new Date().getFullYear() + "-" + new Date().toLocaleString("en-US", { month: "2-digit" }) + "-" + new Date().toLocaleString("en-US", { day: "2-digit" });

    const toggleTab = (index) => {
        setToggleState(index);
    };
    useEffect(() => {
        getEventNewsActivities();
        getThreeEventNewsActivities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEventNewsActivities = async () => {

        const upcomingevents = await fetchData(
            "post",
            get_clientUpcomingEvnets, "",
        );
        // console.log("eventlist", eventnewsactivities);
        // console.log("upcomingevents", upcomingevents);

        if (upcomingevents.code === "1") {
            setUpcomingevents(upcomingevents.data);
            console.log(upcomingevents.data);
        }
    };

    const getThreeEventNewsActivities = async () => {
        const threeeventnewsactivities = await fetchData(
            "post",
            get_three_events_news_activities, "",
        );
        // console.log("threeeventnewsactivities", threeeventnewsactivities);

        if (threeeventnewsactivities.code === "1") {
            setThreeEventNewsActivitiesData(threeeventnewsactivities.data);
            // console.log("event-news-activities", threeeventnewsactivities.data);
        }
    };

    const handleActivities = async (id, name) => {
        navigate(`/activityDetails/${id}`);
    };
    const handleNews = async (id, name) => {
        navigate(`/newsDetails/${id}`);
    };
    const handleEvents = async (id, name) => {
        navigate(`/eventdetails/${id}`);
    };
    const handleEventDetails = async (id, name) => {
        navigate(`/upcomingEventDetails/${id}`);
    };

    return (
        <div>
            <div className='events-card-space eventbg'>
                <div className="container">
                    <h1 className="text-center font"> Events</h1>
                    <div className="row mt-4">

                        <section className='row'>
                            <div className='col-12' >
                                <div className='d-flex py-3 tab-btn justify-content-center'>

                                    <button className={toggleState === 1 ? "UEANtabs active-UEANtabs w-25" : "UEANtabs w-25"} onClick={() => toggleTab(1)}>
                                        Upcoming Events</button>

                                    <button className={toggleState === 2 ? "UEANtabs active-UEANtabs w-25" : "UEANtabs w-25"} onClick={() => toggleTab(2)}>Events</button>

                                    <button className={toggleState === 3 ? "UEANtabs active-UEANtabs w-25" : "UEANtabs w-25"} onClick={() => toggleTab(3)}>Activities</button>

                                    <button className={toggleState === 4 ? "UEANtabs active-UEANtabs w-25" : "UEANtabs w-25"} onClick={() => toggleTab(4)}>News</button>

                                </div>

                                <div className="content-UEANtabs px-3">
                                    <div className={toggleState === 1 ? "UEANcontent  UEANactive-content" : "UEANcontent"}>
                                        {upcomingevents && upcomingevents?.length > 0 ? (
                                            <div className="d-flex flex-column gap-2 ">
                                                {upcomingevents?.map((item, index) =>

                                                    index <= 2 &&

                                                    <div className="row bg-white event-list">
                                                        <div className="col-lg-1 col-md-1 event-day event-time">
                                                            <p className="orange line-height-normal ">
                                                                <span style={{ fontSize: "33px" }}>{new Date(item?.date).toLocaleString("en-US", { day: "2-digit" })}</span>
                                                                <br />
                                                                <span className="event-day text-center">{new Date(item?.date).toLocaleString("en-US", { month: "long" })}</span>
                                                            </p>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="event-img">
                                                                <img
                                                                    className="img-fluid"
                                                                    src={"https://ghelasomnath.org/images/" + item?.photo}
                                                                    alt=""
                                                                />
                                                                <div className='overlay'>
                                                                    <button className="event-view-btn" onClick={() => handleEventDetails(item.id)}>view</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-8 col-md-7 align-self-center event-nm">
                                                            <h4>{item.type}</h4>
                                                            <div className="d-flex gap-3 event-details">
                                                                <p>
                                                                    <span className={item.day ? 'fw-bold' : 'd-none'}>DAY : </span>{" "}
                                                                    {item.day ? item.day : ''} &nbsp;&nbsp;
                                                                    <span className={item.time ? 'fw-bold' : 'd-none'}>TIME : </span>
                                                                    {item.time ? item.time : ''}
                                                                </p>
                                                            </div>
                                                            <p className="text-muted" style={{textAlign:"justify"}}>
                                                               

                                                                {item.description ? (item?.description.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,'')).slice(0, 200) : ""} 
                                                                {item?.description.length>200 &&(
                                                                    <span style={{fontSize:'12px' ,color:'#f16f23',cursor:'pointer'}} onClick={() => handleEventDetails(item.id)}>{item?.description.length>200?' ...READ MORE':''}</span>
                                                                )}
                                                            </p>
                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <h4 style={{ marginTop: "25px" }}>No Upcoming Events{upcomingevents}</h4>
                                        )}
                                    </div>

                                    <div className={toggleState === 2 ? "UEANcontent  UEANactive-content" : "UEANcontent"}>
                                        {threeEventNewsActivitiesData && threeEventNewsActivitiesData?.length > 0 ? (
                                            <div className="d-flex flex-column gap-2 ">
                                                {threeEventNewsActivitiesData?.map((item, index) =>

                                                    item.type === 'Events' && item.date <= currentDate &&

                                                    <div className="row bg-white event-list">
                                                        <div className="col-lg-1 col-md-1 event-day event-time">
                                                            <p className="orange line-height-normal ">
                                                                <span style={{ fontSize: "33px" }}>{new Date(item?.date).toLocaleString("en-US", { day: "2-digit" })}</span>
                                                                <br />
                                                                <span className="event-day text-center">{new Date(item?.date).toLocaleString("en-US", { month: "long" })}</span>
                                                            </p>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="event-img">
                                                                <img
                                                                    className="img-fluid"
                                                                    src={"https://ghelasomnath.org/images/" + item?.photo}
                                                                    alt=""
                                                                />
                                                                <div className='overlay'>
                                                                    <button className="event-view-btn" onClick={() => handleEvents(item.id)}>view</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-8 col-md-7 align-self-center event-nm">
                                                            <h4>{item.type}</h4>
                                                            <div className="d-flex gap-3 event-details">
                                                                <p>
                                                                    <span className={item.day ? 'fw-bold' : 'd-none'}>DAY : </span>{" "}
                                                                    {item.day ? item.day : ''} &nbsp;&nbsp;
                                                                    <span className={item.time ? 'fw-bold' : 'd-none'}>TIME : </span>
                                                                    {item.time ? item.time : ''}
                                                                </p>
                                                            </div>
                                                            <p className="text-muted" style={{textAlign:"justify"}}>
                                                            {item.description ? (item?.description.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,'')).slice(0, 200) : ""} 
                                                                {item?.description.length>200 &&(
                                                                    <span style={{fontSize:'12px' ,color:'#f16f23',cursor:'pointer'}} onClick={() => handleEvents(item.id)}>{item?.description.length>200?' ...READ MORE':''}</span>
                                                                )}
                                                             </p>

                                                            
                                                        </div>

                                                    </div>
                                                )}
                                                <div className="text-center" style={{ marginTop: "3rem" }}>
                                                    <Link className="viewEvents" to="/events">
                                                        View All Events
                                                    </Link>

                                                </div>
                                            </div>
                                        ) : (
                                            <h4 style={{ marginTop: "25px" }}  >Data Not Found!{threeEventNewsActivitiesData}</h4>
                                        )}
                                    </div>

                                    <div className={toggleState === 3 ? "UEANcontent  UEANactive-content" : "UEANcontent"}>
                                        {threeEventNewsActivitiesData && threeEventNewsActivitiesData?.length > 0 ? (
                                            <div className="d-flex flex-column gap-2 ">
                                                {threeEventNewsActivitiesData?.map((item, index) =>

                                                    item.type === 'Activities' &&

                                                    <div className="row bg-white event-list">
                                                        <div className="col-lg-1 col-md-1 event-day event-time">
                                                            <p className="orange line-height-normal ">
                                                                <span style={{ fontSize: "33px" }}>{new Date(item?.date).toLocaleString("en-US", { day: "2-digit" })}</span>
                                                                <br />
                                                                <span className="event-day text-center">{new Date(item?.date).toLocaleString("en-US", { month: "long" })}</span>
                                                            </p>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="event-img">
                                                                <img
                                                                    className="img-fluid"
                                                                    src={"https://ghelasomnath.org/images/" + item?.photo}
                                                                    alt=""
                                                                />
                                                                <div className='overlay'>
                                                                    <button className="event-view-btn" onClick={() => handleActivities(item.id)}>view</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-8 col-md-7 align-self-center event-nm">
                                                            <h4>{item.type}</h4>
                                                            <div className="d-flex gap-3 event-details">
                                                                <p>
                                                                    <span className={item.day ? 'fw-bold' : 'd-none'}>DAY : </span>{" "}
                                                                    {item.day ? item.day : ''} &nbsp;&nbsp;
                                                                    <span className={item.time ? 'fw-bold' : 'd-none'}>TIME : </span>
                                                                    {item.time ? item.time : ''}
                                                                </p>
                                                            </div>
                                                            <p className="text-muted" style={{textAlign:"justify"}}>
                                                            {item.description ? (item?.description.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,'')).slice(0, 200) : ""} 
                                                                {item?.description.length>200 &&(
                                                                    <span style={{fontSize:'12px' ,color:'#f16f23',cursor:'pointer'}} onClick={() => handleActivities(item.id)}>{item?.description.length>200?' ...READ MORE':''}</span>
                                                                )}
                                                            </p>
                                                        </div>

                                                    </div>
                                                )}
                                                <div className="text-center" style={{ marginTop: "3rem" }}>
                                                    <Link className="viewEvents" to="/activities">
                                                        View All Activities
                                                    </Link>

                                                </div>
                                            </div>
                                        ) : (
                                            <h4 style={{ marginTop: "25px" }} >Data Not Found!{threeEventNewsActivitiesData}</h4>
                                        )}
                                    </div>

                                    <div className={toggleState === 4 ? "UEANcontent  UEANactive-content" : "UEANcontent"}>
                                        {threeEventNewsActivitiesData && threeEventNewsActivitiesData?.length > 0 ? (
                                            <div className="d-flex flex-column gap-2 ">
                                                {threeEventNewsActivitiesData?.map((item, index) =>

                                                    item.type === 'News' &&

                                                    <div className="row bg-white event-list">
                                                        <div className="col-lg-1 col-md-1 event-day event-time">
                                                            <p className="orange line-height-normal ">
                                                                <span style={{ fontSize: "33px" }}>{new Date(item?.date).toLocaleString("en-US", { day: "2-digit" })}</span>
                                                                <br />
                                                                <span className="event-day text-center">{new Date(item?.date).toLocaleString("en-US", { month: "long" })}</span>
                                                            </p>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4">
                                                            <div className="event-img">
                                                                <img
                                                                    className="img-fluid"
                                                                    src={"https://ghelasomnath.org/images/" + item?.photo}
                                                                    alt=""
                                                                />
                                                                <div className='overlay'>
                                                                    <button className="event-view-btn" onClick={() => handleNews(item.id)}>view</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-8 col-md-7 align-self-center event-nm">
                                                            <h4>{item.type}</h4>
                                                            <div className="d-flex gap-3 event-details">
                                                                <p>
                                                                    <span className={item.day ? 'fw-bold' : 'd-none'}>DAY : </span>{" "}
                                                                    {item.day ? item.day : ''} &nbsp;&nbsp;
                                                                    <span className={item.time ? 'fw-bold' : 'd-none'}>TIME : </span>
                                                                    {item.time ? item.time : ''}
                                                                </p>
                                                            </div>
                                                            <p className="text-muted" style={{textAlign:"justify"}}>
                                                            {item.description ? (item?.description.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,'')).slice(0, 200) : ""} 
                                                                {item?.description.length>200 &&(
                                                                    <span style={{fontSize:'12px' ,color:'#f16f23',cursor:'pointer'}} onClick={() => handleNews(item.id)}>{item?.description.length>200?' ...READ MORE':''}</span>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>

                                                )}

                                                <div className="text-center" style={{ marginTop: "3rem" }}>
                                                    <Link className="viewEvents" to="/news">
                                                        View All News
                                                    </Link>
                                                </div>
                                            </div>
                                        ) : (
                                            <h4 style={{ marginTop: "25px" }}>Data Not Found!{threeEventNewsActivitiesData}</h4>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventNewsActivity
