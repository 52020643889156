import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { get_eventnewsactivities } from "../../../common/serverUrl";
import fetchData from "../../../common/fetchData";
import { Link, useLocation } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
import Header from '../../Header/Header';
import { IoLocationSharp } from "react-icons/io5";
import { CgClose } from "react-icons/cg";
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import './Events.css'

const NewsDetails = () => {
  let { id } = useParams();

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [eventnewsactivitiesImage, setEventNewsActivitiesImage] = useState();
  const [eventnewsactivitiesName, setEventNewsActivitiesName] = useState();
  const [eventnewsactivitiesDay, setEventNewsActivitieDay] = useState();
  const [eventnewsactivitiesDate, setEventNewsActivitieDate] = useState();
  const [eventnewsactivitiesTime, setEventNewsActivitieTime] = useState();
  const [eventnewsactivitiesDescription, setEventNewsActivitiesDescription] = useState();
  const [eventnewsactivitiesPlace, setEventNewsActivitiesPlace] = useState();
  const [tempimgsrc, setTempImgSrc] = useState('');


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    getEventNewsActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventNewsActivities = async () => {
    const eventnewsactivities = await fetchData(
      "post",
      get_eventnewsactivities, "",
      {
        id: id
      }
    );
    if (eventnewsactivities.code === "1") {
      // console.log(eventnewsactivities.data[0]);
      setEventNewsActivitiesImage(eventnewsactivities.data[0].photo);
      setEventNewsActivitiesName(eventnewsactivities.data[0].type);
      setEventNewsActivitieDay(eventnewsactivities.data[0].day);
      setEventNewsActivitieTime(eventnewsactivities.data[0].time);
      setEventNewsActivitieDate(eventnewsactivities.data[0].date);
      setEventNewsActivitiesDescription(eventnewsactivities.data[0].description.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,''));
      setEventNewsActivitiesPlace(eventnewsactivities.data[0].place);
    }
    else {
      console.log("not found");
    }
  };

  const date = new Date(eventnewsactivitiesDate)
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric"
  })

  const getImg = (imgsrc) => {
    console.log(imgsrc)
    setTempImgSrc(imgsrc);
  }

  return (
    <div>
      <Header />
      {loading ? (
        <div className="loader-container" >
          <div className="loader-icon">
            <img src='/assets/img/logo2.png' alt="" />
            <img src="/assets/img/loading5.gif" alt="" />
          </div>
        </div>
      ) : (
        <>
          <section className="innerPageHeader">
            <div className="container" >
              <div className='px-5'>
                <h2 className='fs-white m-0 font'>News Details</h2>
                <ol className="breadcrumb fs-6">
                  <li className="breadcrumb-item">
                    <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                      <span className='backpath'>Home </span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to='/news' className={`${location.pathname === "/news" ? "btn-link" : ""}`}>
                      <span className='backpath'>News</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">NewsDetails</li>
                </ol>
              </div>
            </div>
          </section>

          <div className="starting-space">
            <div className="container">
              <div className="event-detail-space">
                <div className="card mb-3 ">
                  <div className="row g-0">
                    <div className="col-md-5 ed-img">
                      <img src={"https://ghelasomnath.org/images/" + eventnewsactivitiesImage} className="img-fluid rounded-start" style={{ height: "100%" ,cursor: 'pointer'}} alt="ghelasomnath news" data-bs-toggle="modal" data-bs-target="#newsImg" onClick={() => getImg("https://ghelasomnath.org/images/" + eventnewsactivitiesImage)}
                      />
                    </div>

                    <div className="col-md-7">
                      <div className="card-body">

                        <h5 className="card-title"><Link to="event-details.html"></Link>{eventnewsactivitiesName}</h5>

                        <p className="card-text">{eventnewsactivitiesName}</p>

                        <div className="d-flex flex-column gap-2">
                          <Link to=""> {eventnewsactivitiesTime ? '🕔 ' + eventnewsactivitiesTime : ''} </Link>
                          <Link to="">{eventnewsactivitiesDay ? '🕔 ' + eventnewsactivitiesDay : ''} </Link>
                          <Link to="">{eventnewsactivitiesDate ? '🕔 ' + formattedDate : ''} </Link>
                          <Link to=""><IoLocationSharp className="event-location" /> {eventnewsactivitiesPlace} </Link>
                          <p className="text-muted" style={{textAlign:"justify"}}><PiArrowFatLinesRightFill className='fs-4' />&nbsp;&nbsp; {eventnewsactivitiesDescription}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="img-modal">
            <div className="modal fade " id="newsImg" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className=" modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <img src={tempimgsrc} alt="" />
                </div>
              </div>
              <button type="button" className="model-close" data-bs-dismiss="modal" aria-label="Close"><CgClose /></button>
            </div>
          </div>

          <FooterPage />
        </>
      )}
    </div>
  )
}

export default NewsDetails
