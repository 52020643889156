import React, { useEffect, useState } from "react";
import { add_donation, payment } from "../../../common/serverUrl";
import fetchData from "../../../common/fetchData";
import { Link, useLocation } from "react-router-dom"; import FooterPage from "../../Footer/FooterPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "react-bootstrap/Toast";
import './Donation.css'
import Header from '../../Header/Header';
import { Country, State, City } from 'country-state-city';
import Select from "react-select";
import QrCode from "../../Layout/QrCode/QrCode"

function Donation() {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const location = useLocation();
    const [Name, setName] = useState("");
    const [Phone, setPhone] = useState("");
    const [Email, setEmail] = useState("");
    const [Address, setAddress] = useState("");
    const [Zip, setZip] = useState("");
    const [Amount, setAmount] = useState();
    const [isError, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(false);
    const [message, setMessage] = useState("");

    const phoneNumber = event => {
        const limit = 10;
        setPhone(event.target.value.slice(0, limit));
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        let date_ob = new Date();
        let date = ("0" + date_ob.getDate()).slice(-2);
        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        // current year
        let year = date_ob.getFullYear();
        var donation_date = year + "-" + month + "-" + date;

        const response = await fetchData(
            "post",
            add_donation,
            " ",
            {
                name: Name,
                phone: Phone,
                city: selectedCity.name,
                state: selectedState.name,
                country: selectedCountry.name,
                address: Address,
                zip: Zip,
                payment_amount: Amount,
                payment_status: "Pending",
                donation_date: donation_date,
                email: Email
            }
        )
        // console.log("Donation success", response.code)
        if (response.code == "1") {
            console.log("Payment --> ", response.code == "1");
            fetchData(
                "get",
                payment,
                " ",
                {}
            ).then((responsee) => {
                const formBlob = new Blob([responsee], { type: 'text/html' }); // Adjust the MIME type according to your form data
                // Create a URL for the Blob object
                const formUrl = URL.createObjectURL(formBlob);
                // Open the form in a new tab
                window.open(formUrl, '_self');
                // console.log("--------->>", responsee)
            });
        }

        // console.log("--------------------->",response);
        // if (response.code === "1") {
        // const responsee = await fetchData(
        //     "get",
        //     payment,
        //     " ",
        //     {
        //     }
        // );

        // const formBlob = new Blob([responsee], { type: 'text/html' }); // Adjust the MIME type according to your form data
        // // Create a URL for the Blob object
        // const formUrl = URL.createObjectURL(formBlob);
        // // Open the form in a new tab
        // window.open(formUrl, '_self');
        // // console.log("--------->>", responsee)
        // }

    };

    const handleDonationAmount = async (amount) => {
        // console.log(amount);
        setAmount(amount);
        setSelectedTabIndex(amount);
    };

    // console.log({ selectedTabIndex });

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <div>
            <Header />
            {loading ? (
                <div className="loader-container" >
                    <div className="loader-icon">
                        <img src='/assets/img/logo2.png' alt="" />
                        <img src="/assets/img/loading5.gif" alt="" />
                    </div>
                </div>
            ) : (
                <>
                    <section className="innerPageHeader">
                        <div className="container" >
                            <div className='px-5'>

                                <h2 className='fs-white m-0 font'>Donation</h2>
                                <ol className="breadcrumb fs-6">
                                    <li className="breadcrumb-item">
                                        <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                                            <span className='backpath'>Home </span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Donate</li>
                                </ol>
                            </div>
                        </div>
                    </section>

                    {isError && message !== "" && (
                        <ToastContainer className="p-3" position="top-center">
                            <Toast>
                                <Toast.Header>
                                    <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded me-2"
                                        alt="" />
                                    <strong className="me-auto" style={{ color: "red" }}>
                                        {isError ? "Error" : "Success"}
                                    </strong>
                                </Toast.Header>
                                <Toast.Body>{message}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    )}
                    <QrCode />
                    <div className="donation">

                        <div className="container">

                            <div className=" text-center">
                                <h2>Make a donation <br /> to help us</h2>
                            </div>

                            <form onSubmit={onSubmit}>
                                <div className="form-row sigma_donation-form py-4">

                                    <div className="col-lg-4 col-sm-12">
                                        <div className="form-group mb-5">
                                            <h6 className="ps-3">Donation Amount</h6>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <button className="donate-amount btn-sm" type="button">₹</button>
                                                </div>
                                                <input type="number" className="form-control ms-0" onChange={(e) => setAmount(e.target.value)} required value={Amount} placeholder="Please select / enter Amount" />
                                            </div>
                                            <div className="d-flex gap-2 donation-money">
                                                <Link className={selectedTabIndex === "1" ? "active" : ""} onClick={() => handleDonationAmount("1")}>₹1</Link>
                                                <Link className={selectedTabIndex === "100" ? "active" : ""} onClick={() => handleDonationAmount("100")}>₹100</Link>
                                                <Link className={selectedTabIndex === "500" ? "active" : ""} onClick={() => handleDonationAmount("500")}>₹500</Link>
                                                <Link className={selectedTabIndex === "1000" ? "active" : ""} onClick={() => handleDonationAmount("1000")}>₹1000</Link>
                                                <Link className={selectedTabIndex === "5000" ? "active" : ""} style={{}} onClick={() => handleDonationAmount("5000")}>₹5000</Link>
                                            </div>
                                            <div className="d-flex gap-2 donation-money">
                                                <Link className={selectedTabIndex === "11000" ? "active" : ""} onClick={() => handleDonationAmount("11000")}>₹11000</Link>
                                                <Link className={selectedTabIndex === "100000" ? "active" : ""} onClick={() => handleDonationAmount("100000")}>₹100000</Link>
                                                <Link className={selectedTabIndex === "1100000" ? "active" : ""} onClick={() => handleDonationAmount("1100000")}>₹1100000</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-8 col-sm-12">
                                        <div className="form-group">
                                            <h6 className="ps-2">Donar</h6>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <i className="far fa-user"></i>
                                                        <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} required placeholder="Full Name" name="name" />
                                                    </div>

                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 ">
                                                    <div className="form-group">
                                                        <i className="far fa-envelope"></i>
                                                        <input type="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} required name="email" className="form-control " />
                                                    </div>
                                                    <div className="form-group">
                                                        <i className="far fa-phone"></i>
                                                        <input type="number" placeholder="Phone" name="phone" onChange={phoneNumber} value={Phone} required className="form-control " />
                                                    </div>
                                                    <div className="form-group">
                                                        <i className="far fa-file"></i>
                                                        <input type="text" name="zip" className="form-control" onChange={(e) => setZip(e.target.value)} required placeholder="Pincode" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            placeholder="Select Country"
                                                            options={Country.getAllCountries()}
                                                            getOptionLabel={(options) => {
                                                                return options["name"];
                                                            }}
                                                            getOptionValue={(options) => {
                                                                return options["name"];
                                                            }}
                                                            value={selectedCountry}
                                                            onChange={(item) => {
                                                                setSelectedCountry(item);
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <Select
                                                            className="form-control"
                                                            placeholder="Select State"
                                                            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                                            getOptionLabel={(options) => {
                                                                return options["name"];
                                                            }}
                                                            getOptionValue={(options) => {
                                                                return options["name"];
                                                            }}
                                                            value={selectedState}
                                                            onChange={(item) => {
                                                                setSelectedState(item);
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <Select className="form-control"
                                                            placeholder="Select City"
                                                            options={City.getCitiesOfState(
                                                                selectedState?.countryCode,
                                                                selectedState?.isoCode
                                                            )}
                                                            getOptionLabel={(options) => {
                                                                return options["name"];
                                                            }}
                                                            getOptionValue={(options) => {
                                                                return options["name"];
                                                            }}
                                                            value={selectedCity}
                                                            onChange={(item) => {
                                                                setSelectedCity(item);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <i className="far fa-home"></i>
                                                        <textarea type="text" name="address" className="form-control" placeholder="Address" rows="4" onChange={(e) => setAddress(e.target.value)} value={Address} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center gap-2">
                                        <button type="submit" className="common-btn" name="button"> Donate Now </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="donation-detail">
                        <div className="col-lg-12 d-flex flex-column gap-3">
                            <h3>ગૌદાન માહિતી</h3>
                            <p style={{ textAlign: "justify" }}>ભારત દેશમાં આદિકાળથી ગાયને દેવીય સ્વરૂપે પૂજવામાં આવે છે. ગૌ-રક્ષા એ હિંદુત્વનો મધ્યવર્તી વિચાર છે આપણા વિવિધ પુરાણો અને શાસ્ત્રોમાં જણાવ્યા મુજબ ગૌ-માતામાં ૩૩ કરોડ દેવી-દેવતાઓનો વાસ છે. શ્રી ઘેલા સોમનાથ મંદિર ટ્રસ્ટ ગૌશાળાની ગાય માતાઓને તેમના ખોરાક, પાણી, દવા અને અન્ય જરૂરીયાતોને પહોંચી વળવા ગૌ-માતાને દત્તક લેવા ધર્મપ્રેમી જનતાને ગૌ-રક્ષાના ઉમદા કાર્યમાં સહભાગી બનવા અનુરોધ કરવામાં આવે છે.
                                આપના જીવનના વિવિધ યાદગાર પ્રસંગોને આજીવન યાદગાર બનાવવા આપ ગૌ-માતાને દત્તક લઈ ગૌ-માતાના ભગીરથ યજ્ઞમાં આપનું યોગદાન આપી શકો છો.
                            </p>
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                <h6 style={{ "color": "#777" }}>શ્રી ઘેલા સોમનાથ ટ્રસ્ટ સરેરાશ ૧ર૦ ગાયોનો નિભાવ કરે છે.</h6>
                                <div className="pt-3 donate-table">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th>એક ગાયને દતક લેવા માટે સમય ગાળો</th>
                                                <th>એક ગાયને દતક લેવા માટે દાનની રકમ</th>
                                            </tr>
                                            <tr>
                                                <td>૧ દિવસ માટે</td>
                                                <td>રૂા. ૧૫૧/-</td>
                                            </tr>
                                            <tr>
                                                <td>૧ અઠવાડીયા માટે</td>
                                                <td>રૂા. ૧,૦૫૧/-</td>
                                            </tr>
                                            <tr>
                                                <td> ૧ મહિના માટે</td>
                                                <td>રૂા. ૫,૦૫૧/-</td>
                                            </tr>
                                            <tr>
                                                <td>૧ વર્ષ માટે</td>
                                                <td>રૂા. ૫૦,૦૫૧/-</td>
                                            </tr>
                                            <tr>
                                                <td>આજીવન માટે</td>
                                                <td>રૂા. ૧૦,૦૦,૦૫૧/-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterPage />
                </>
            )}
        </div>
    );
}
export default Donation
