import React from 'react';
import { Link } from "react-router-dom";
import './about.css'

const About = () => {
 

  return (
    <>
      <div className='about-bg-img'>

        <div className="container" >
          <div className="row l-about">
            <div className="col-lg-6 col-md-6">
              <img className='img-fluid' src="https://ghelasomnath.org/assets/img/about/ghelaSomnath-mahadev.jpg" style={{ height: "50vh", width: "100%", padding: "0 3vmax" }} alt="about" />
            </div>
            <div className="col-lg-6 col-md-6 align-self-center d-flex flex-column gap-3 justify-content-center align-items-center">
              <h2 className='mt-1'>History of Temple</h2>
              <p className="about-data" style={{ fontSize: "13px",textAlign:"justify" }}> વેરાવળ પ્રભાસપાટણ પાસે આવેલા સોમનાથ મંદિરને લુંટવા તથા મંદિરનો નાશ કરવા માટે એ સમયે મહમદ ગઝનીએ બે-ત્રણ વાર હુમલો કર્યો હતો, પરંતુ તેને તેમાં નિષ્ફળતા મળી હતી આ સમયે જુનાગઢ ઉપર કુંવર મહિપાલની કુંવરી મીનળદેવી કે જે શિવભકિતમાં તલ્લીન હતા અને મુસ્લીમ રાજાઓથી બચવા તેમણે શિવલીંગની સ્થાપના ભુર્ગભમાં કરી હતી અને ત્યાં જ પુજા કરતા હતા આમ મીનળદેવીને ભોળાનાથમાં અપાર શ્રધ્ધા હતી. </p>
              <Link to="/aboutus" className='about-btn mx-auto'>Read More</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
