import React, { useEffect, useState } from "react";
import './Events.css';
import Header from '../../Header/Header'
import { Link, useLocation } from 'react-router-dom';
import { get_eventnewsactivities } from "../../../common/serverUrl";
import { useNavigate } from "react-router-dom";
import fetchData from "../../../common/fetchData";
import FooterPage from "../../Footer/FooterPage";

const AllEvent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [eventnewsactivitiesData, setEventNewsActivitiesData] = useState();
  const currentDate = new Date().getFullYear() + "-" + new Date().toLocaleString("en-US", { month: "2-digit" }) + "-" + new Date().toLocaleString("en-US", { day: "2-digit" });
  // console.log(currentDate)
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    getEventNewsActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventNewsActivities = async () => {
    const eventnewsactivities = await fetchData(
      "post",
      get_eventnewsactivities, "",
    );
    // console.log("eventlist", eventnewsactivities);
    if (eventnewsactivities.code === "1") {
      // console.log(eventnewsactivities.data);
      setEventNewsActivitiesData(eventnewsactivities.data);
    }
    else {
      console.log("not found");
    }
  };
  const handleEventDetails = async (id, name) => {
    navigate(`/eventdetails/${id}`);
  };

  return (
    <>
      <Header />
      {loading ? (
        <div className="loader-container" >
          <div className="loader-icon">
            <img src='/assets/img/logo2.png' alt="" />
            <img src="/assets/img/loading5.gif" alt="" />
          </div>
        </div>
      ) : (
        <>
          <section className="innerPageHeader">

            <div className="container">
              <div className='px-5'>
                <h2 className='fs-white m-0'>Event List</h2>
                <ol className="breadcrumb fs-6">
                  <li className="breadcrumb-item">
                    <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                      <span className='backpath'>Home </span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Events</li>
                </ol>
              </div>
            </div>
          </section>

          <div className="eventbg" style={{ padding: "4vmax 0rem" }}>
            <div className="container">
              <h1 className="text-center font">Events</h1>
              <p className="text-center my-3 orange ">SEE EVENTS</p>
              <div style={{ marginTop: "2vmax" }}>
                {eventnewsactivitiesData && eventnewsactivitiesData?.length > 0 ? (
                  <div className="d-flex flex-column gap-2 events">
                    {eventnewsactivitiesData?.map((item, index) => (

                      item.type === "Events" && item.date <= currentDate &&

                      <div className="row bg-white event-lists">
                        <div className="col-lg-1 col-md-1 event-day event-time">
                          <p className="orange line-height-normal">
                            <span style={{ fontSize: "33px" }}>{new Date(item?.date).toLocaleString("en-US", { day: "2-digit" })}</span>
                            <br />
                            <span className="event-day">{new Date(item?.date).toLocaleString("en-US", { month: "long" })}</span>
                          </p>
                        </div>

                        <div className="col-lg-3 col-md-4">
                          <div className="event-img">
                            <img
                              className="img-fluid"
                              src={"https://ghelasomnath.org/images/" + item?.photo}
                              alt="ghelasomnath events"
                            />
                            <div className='overlay'>
                              <button className="event-view-btn" onClick={() => handleEventDetails(item.id)}>view</button>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8 col-md-7 align-self-center event-nm">
                          <h4>{item.type}</h4>
                          <div className="d-flex gap-3 event-details">
                            <p>
                              <span style={{ color: '#272e38' }} className={item.day ? 'fw-bold' : 'd-none'}>DAY : </span>{" "}
                              {item.day ? item.day : ''} &nbsp;&nbsp;
                              <span style={{ color: '#272e38' }} className={item.time ? 'fw-bold' : 'd-none'}>TIME : </span>
                              {item.time ? item.time : ''}
                            </p>
                          </div>
                          <p className="text-muted" style={{textAlign:"justify"}}>
                            {/* {parse(item.description)} */}
                            {item.description ? (item?.description.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/gi,'')).slice(0, 230) : ""}
                            {item?.description.length > 230 && (
                              <span style={{ fontSize: '12px', color: '#f16f23', cursor: 'pointer' }} onClick={() => handleEventDetails(item.id)}>{item?.description.length > 230 ? ' ...READ MORE' : ''}</span>
                            )}
                          </p>
                        </div>

                      </div>
                    ))}
                  </div>
                ) : (
                  <h2  >Data Not Found!{eventnewsactivitiesData}</h2>
                )}
              </div>
            </div>
          </div>
          <FooterPage />
        </>
      )}
    </>
  )
}

export default AllEvent
