const mainUrl = "https://ghelasomnath.org:8081/api/v1/user";
//  const mainUrl = "http://localhost:8081/api/v1/user";

////commitee
const get_commitee = mainUrl + "/getClientTrusteeDetails";
const get_rooms_category = mainUrl + "/getClientRoomCategory";
const add_rooms_bookings = mainUrl + "/addRoomBookings";
const get_client_gallery = mainUrl + "/getClientGallery";
const get_eventnewsactivities = mainUrl + "/getClientEvnetsNewsActivities";
const get_clientUpcomingEvnets = mainUrl + "/getClientUpcomingEvnets";
const get_puja = mainUrl + "/getClientPooja";
const add_puja_booking = mainUrl + "/addPoojaBookings";
const add_donation = mainUrl + "/addDonation";
const add_query = mainUrl + "/addClientQuery";
const get_rooms_category_by_id = mainUrl + "/getClientRoomCategoryById";
const sendemail = mainUrl + "/sendEmail";
const sendemailDonation = mainUrl + "/sendEmailDonation";
const get_social_links = mainUrl + "/getSocialLinks";
const payment = mainUrl + "/payment";
const check_available_rooms = mainUrl + "/checkAvailableRooms";
const get_three_events_news_activities =
  mainUrl + "/getThreeEvnetsNewsActivities";
const send_email_donation = mainUrl + "/sendEmailDonation";
const send_email_pooja = mainUrl + "/sendEmailPooja";
const send_email_room = mainUrl + "/sendEmailRoom";

// auth
const sign_in = mainUrl + "/login";
const sign_up = mainUrl + "/addUser";

export {
  sign_in,
  sign_up,
  get_commitee,
  get_rooms_category,
  add_rooms_bookings,
  get_client_gallery,
  get_eventnewsactivities,
  get_clientUpcomingEvnets,
  get_puja,
  add_puja_booking,
  add_donation,
  add_query,
  get_rooms_category_by_id,
  sendemail,
  sendemailDonation,
  get_social_links,
  payment,
  check_available_rooms,
  get_three_events_news_activities,
  send_email_donation,
  send_email_pooja,
  send_email_room,
};
