import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get_rooms_category } from "../../../common/serverUrl";
import fetchData from "../../../common/fetchData";
import "./blog-room.css";

const BlogRoom = () => {
  const navigate = useNavigate();

  const [roomsData, setRoomsData] = useState();
  useEffect(() => {
    getRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRooms = async () => {
    const rooms = await fetchData("post", get_rooms_category, "");
    // console.log(rooms);
    if (rooms.code === "1") {
      // console.log(rooms.data);
      setRoomsData(rooms.data);
    } else {
      console.log("not found");
    }
  };

  const today = new Date();
  const dd = String(today.getDate() + 1).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  var current_date = yyyy + "-" + mm + "-" + dd;

  const handleRoomBooking = async (id) => {
    if(current_date > "2023-09-15"){
      navigate(`/roombooking/${id}`);
    }
    else{
      navigate(`/room`);
    }
  };

  return (
    <div style={{ backgroundColor: "#282828" }}>
      <div className="container">
        <div className="space-top">
          {roomsData && roomsData?.length > 0 && (
            <div
              className="row gap-1 justify-content-center"
              style={{ padding: "0 14%", cursor: "pointer" }}
            >
              {roomsData.map(
                (item, index) =>
                  index <= 7 && (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 puja-type"
                      onClick={() => handleRoomBooking(item.id)}
                    >
                      <h6 className="room">Room ➱</h6>
                      <p className="p-nm">{item?.name}</p>
                      <p className="r-price">Price ₹{item?.price}</p>
                      {(current_date > "2023-09-15") && (<div>
                        <button
                          className="room-book-btn"
                          onClick={() => handleRoomBooking(item.id)}
                        >
                          Book
                        </button>
                      </div>)}
                       
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogRoom;
