import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './header.css'

const Header = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  // sticky header
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    if (location.pathname === "/") {
      scrollTop >= 600 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    }
    else {
      scrollTop >= 150 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    }
  };

  return (
    <>
      
      <div className="sigma_aside-overlay aside-trigger-right" onClick={() => setIsActive(!isActive)}></div>
      <aside className={isActive ? 'sigma_aside sigma_aside-left open' : 'sigma_aside sigma_aside-left'}>
        <Link to='/' className={`${location.pathname === "/" ? "waves-effect navbar-brand active" : ""}`} >
          <img src="https://ghelasomnath.org/assets/img/logo-new.jpg" style={{ "height": "90x", "width": "130px" }} alt="logo" />
        </Link>
        <ul>
          <li className="menu-item menu-item-has-children">
            <Link to='/' className={`${location.pathname === "/" ? "waves-effect active" : ""}`} >
              HOME
            </Link>
          </li>
          <li className="menu-item menu-item-has-children">
            <Link to='/aboutus' className={`${location.pathname === "/aboutus" ? "waves-effect active" : ""}`}>
              About Us
            </Link>
          </li>
          <li className="menu-item menu-item-has-children">
            <Link to='/events' className={`${location.pathname === "/allevents" ? "waves-effect active" : ""}`}>
              Event
            </Link>
          </li>
          <li className="menu-item menu-item-has-children">
            <Link to='/puja' className={`${location.pathname === "/puja" ? "waves-effect active" : ""}`}>
              Puja
            </Link>
          </li>
          <li className="menu-item menu-item-has-children">
            <Link to='/commitee' className={`${location.pathname === "/commitee" ? "waves-effect active" : ""}`}>
              Commitee
            </Link>
          </li>
          <li className="menu-item menu-item-has-children">
            <Link to='/room' className={`${location.pathname === "/room" ? "waves-effect active" : ""}`}>
              Room
            </Link>
          </li>
          <li className="menu-item menu-item-has-children">
            <Link to='/gallery' className={`${location.pathname === "/gallary" ? "waves-effect active" : ""}`}>
              Gallery
            </Link>
          </li>
          <li exact className="menu-item menu-item-has-children">
            <Link to='/contactus' className={`${location.pathname === "/contactus" ? "waves-effect active" : ""}`}>
              Contact Us
            </Link>
          </li>
          <li exact className="menu-item menu-item-has-children">
            <Link to='/live-darshan' className={`${location.pathname === "/live-darshan" ? "waves-effect active" : ""}`}>
              Live Darshan
            </Link>
          </li>
          <li className="menu-item menu-item-has-children donationbuttonsmall">
            <Link to='/donate' className={`${location.pathname === "/donate" ? "waves-effect active" : ""}`}>
              Donate Now
            </Link>
          </li>
        </ul>

      </aside>

      <div className="sigma_aside-overlay aside-trigger-left " onClick={() => setIsActive(!isActive)}></div>
      <header className="sigma_header header-section">
        <div className="sigma_header-middle shadow-sm">
          <nav className="navbar">
            {/* <marquee>
                    માણસ સુખી થવા મકાન બદલે છે. વસ્ત્રો બદલે છે ગાડી બદલે છે. મોબાઇલ બદલે છે. છતા દુઃખી છે. કારણ કે તે પોતાનો સ્વભાવ નથી બદલતો...         લાપરવાહી અજ્ઞાનતા કરતા પણ વધારે નુકશાન પહોંચાડે છે શરીરથી વિવેક મનથી સમાનતા અને આત્માથી એકતા એ સાચાસત્યથી કિર્તીપ્રાપ્ત થાય અને સહયોગથી મિત્રો બનાવાય છે    સાધુના લક્ષણો છે શરીરથી વિવેક મનથી સમાનતા અને આત્માથી એકતા એ સાચા સાધુના લક્ષણો છે     કુમંત્રણાથી રાજાનો, કુસંગતથી સાધુનો, વધુ પડતા દુલારથી પુત્રનો અને અવિધાથી બ્રાહ્મણનો નાશ થાય છે    તલવારનો ઘા તો થોડા દિવસોમાં ભરાઇ જશે. પરંતુ અપમાનનો ઘા કયારેય ભરાતો નથી માત્ર એવા લોકોને પોતાની ચારે બાજુ રાખો જે તમોને વધુ ને વધુ ઉપર લઇ જાય
                </marquee>   */}
            <div className="sigma_header-controls style-2">
              <Link to='/'>
                <img src="https://ghelasomnath.org/assets/img/logo2.png" className="header-logo" alt="logo" />
              </Link>
            </div>

            <ul className="navbar-nav" onClick={() => setIsActive(!isActive)}>
              <li className="menu-item menu-item-has-children">
                <Link to='/' className={`${location.pathname === "/" ? "waves-effect active" : ""}`} >
                  HOME
                </Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to='/aboutus' className={`${location.pathname === "/aboutus" ? "waves-effect active" : ""}`}>
                  About Us
                </Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to='/events' className={`${location.pathname === "/events" ? "waves-effect active" : ""}`}>
                  Event
                </Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to='/puja' className={`${location.pathname === "/puja" ? "waves-effect active" : ""}`}>
                  Puja
                </Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to='/commitee' className={`${location.pathname === "/commitee" ? "waves-effect active" : ""}`}>
                  Commitee
                </Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to='/room' className={`${location.pathname === "/room" ? "waves-effect active" : ""}`}>
                  Room
                </Link>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link to='/gallery' className={`${location.pathname === "/gallery" ? "waves-effect active" : ""}`}>
                  Gallery
                </Link>
              </li>
              <li exact className="menu-item menu-item-has-children">
                <Link to='/contactus' className={`${location.pathname === "/contactus" ? "waves-effect active" : ""}`}>
                  Contact Us
                </Link>
              </li>
              <li exact className="menu-item menu-item-has-children">
                <Link to='/live-darshan' className={`${location.pathname === "/live-darshan" ? "waves-effect active" : ""}`}>
                  Live Darshan
                </Link>
              </li>
            </ul>
            <div className="">
              <div className="align-self-center donationbutton donate-menu-btn">
                <Link exact to='/donate' className={`${location.pathname === "/donate" ? "donate-button-active active" : ""}`}>
                  <i className='fa fa-heart me-1'></i> Donate Now
                </Link>
              </div>
            </div>
            <div className="sigma_header-controls style-2">
              <ul className="sigma_header-controls-inner">
                <div className="aside-toggler">

                  <li className="style-2 aside-trigger-right desktop-toggler" onClick={() => setIsActive(!isActive)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </li>



                  <li className="aside-toggler style-2 aside-trigger-left" onClick={() => setIsActive(!isActive)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </li>
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header
