import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import './Commitee.css';
import Header from '../../Header/Header';
import { FaPhoneAlt } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

import FooterPage from '../../Footer/FooterPage';
import { get_commitee, get_social_links } from "../../../common/serverUrl";
import fetchData from "../../../common/fetchData";

const Commitee = () => {

    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [commiteeData, setcommiteeData] = useState();
    const [facebookData, setFaceBookData] = useState();
    const [twitterData, setTwitterData] = useState();

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        getcommitee();
        getSocialLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSocialLink = async () => {
        const sociallink = await fetchData(
            "post",
            get_social_links, "",
        );
        // console.log(sociallink);
        if (sociallink.code === "1") {
            console.log("tt", sociallink.data.facebook);
            setTwitterData(sociallink.data.twitter);
            setFaceBookData(sociallink.data.facebook);
        }
        else {
            console.log("not found");
        }
    };

    const getcommitee = async () => {
        const commitee = await fetchData(
            "post",
            get_commitee, "",
        );
        // console.log(commitee);
        if (commitee.code === "1") {
            // console.log(commitee.data);
            setcommiteeData(commitee.data);
        }
        else {
            console.log("not found");
        }
    };
    return (
        <>
            <Header />
            {loading ? (
                <div className="loader-container" >
                    <div className="loader-icon">
                        <img src='/assets/img/logo2.png' alt="" />
                        <img src="/assets/img/loading5.gif" alt="" />
                    </div>
                </div>
            ) : (
                <>
                    <section className="innerPageHeader">
                        <div className="container" >
                            <div className='px-5'>
                                <h2 className='fs-white m-0 font'>Commitee</h2>
                                <ol className="breadcrumb fs-6">
                                    <li className="breadcrumb-item">
                                        <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                                            <span className='backpath'>Home </span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Commitee</li>
                                </ol>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container" >
                            <div className="row puja-space">
                                <div className="col-lg-7 col-md-6 col-sm-12 d-flex flex-column gap-3 align-self-center">
                                    <h1>MEMBERS</h1>
                                    <p className='text-muted commitee-member' style={{ fontSize: "15px", textAlign: 'justify' }}>આ મંદિરમાં સને ૨૦૦૫-૦૬ માં સરકારશ્રીએ પ્રવાસન વર્ષમાં સમાવેશ કરતા છેલ્લા ત્રણથી ચાર વર્ષમાં સરકારશ્રીના સહયોગથી અને સરકારી ગ્રાન્ટમાંથી તેમજ લોકમેળા સમિતિ-રાજકોટ અને લોક ભાગીદારી, દાતાઓના દાનથી આ જગ્યામાં ઘણો જ વિકાસ કરવામાં આવેલ છે. અને આ સમય દરમ્યાન મંદિરમાં આશરે પાંચ થી છ કરોડનો અંદાજીત ખર્ચ કરવામાં આવ્યો છે. યાત્રીકો અને ભાવિકો માટેના આરામ અને આનંદ માટે બાગ બગીચાઓ બનાવામાં આવ્યા છે. </p>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12 commitee-img mt-2">
                                    <img className='img-fluid' src="https://ghelasomnath.org/assets/img/puja/puja-img/bilipatra.jpg" alt="bilipatra shivling" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className='commitee-card-space' style={{ background: "url(https://ghelasomnath.org/assets/img/about/aboutbg.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                        <div className="container">
                            <h1 className='text-center'>COMMITEE</h1>
                            {commiteeData && commiteeData?.length > 0 ? (
                                <div className="row " style={{ marginTop: "1vmax" }}>
                                    {commiteeData?.map((item, index) => (
                                        <div className="col-xl-3 col-lg-4 col-md-6" style={{ marginTop: "2rem" }}>

                                            <div class="alumn3_serviceBox">
                                                <div class="service-image">
                                                    <img src={item.photo ? "https://ghelasomnath.org/images/" + item.photo : "assets/img/user-1.jpg"} alt="" />
                                                </div>
                                                <div class="service-content">
                                                    <h3>{item.name}</h3>
                                                    <h6> {item.designation} </h6>
                                                    <p> {item.description} </p>
                                                    <div className='contact-links'>
                                                        {item.phone && <div>
                                                            <a href={`tel:${item.phone}`} target="_blank" rel="noopener noreferrer" title='call'>
                                                                <FaPhoneAlt />
                                                            </a>
                                                        </div>}

                                                        {item.email && <div>
                                                            <a href={`mailto:${item.email}`} target="_blank" rel="noopener noreferrer" title='Email'>
                                                                <SiGmail />
                                                            </a>
                                                        </div>}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <h2>Data Not Found!{commiteeData}</h2>
                            )}
                        </div>
                    </div>
                    <FooterPage />
                </>
            )}
        </>
    )
}

export default Commitee
