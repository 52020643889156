import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Commitee from "./components/InnerPage/commitee/Commitee";
import AboutUs from "./components/InnerPage/about/AboutUs";
import ContactUs from "./components/InnerPage/contactUs/ContactUs";
import AllEvent from "./components/InnerPage/events/AllEvent";
import Room from "./components/InnerPage/room-booking/Room";
import RoomBooking from "./components/InnerPage/room-booking/RoomBooking";
import PoojaBooking from "./components/InnerPage/puja/PujaBooking";
import Gallery from "./components/InnerPage/gallery/Gallery";
import Login from "./components/Layout/login";
import Register from "./components/Layout/register";
import Pooja from "./components/InnerPage/puja/Pooja";
import Donation from "./components/InnerPage/donation/Donation.jsx";
import EventDetails from "./components/InnerPage/events/EventDetails";
import Stuti from "./components/InnerPage/stuti/Stuti";
import TermsandCondition from "./components/InnerPage/term&condition/TermsAndCondition";
import UserPolicy from "./components/InnerPage/userPolicy/UserPolicy";
import PrivacyPolicy from "./components/InnerPage/privacyPolicy/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import LiveDarshan from "./components/InnerPage/liveDarshan/liveDarshan";
import Activities from "./components/InnerPage/events/Activities";
import News from "./components/InnerPage/events/News";
import ActivityDetails from "./components/InnerPage/events/ActivityDetails";
import NewsDetails from "./components/InnerPage/events/NewsDetails";
import UpcomingEventDetails from "./components/InnerPage/events/UpcomingEventDetails";
import SuccessPage from "./components/InnerPage/success/Success";
import FailedPaymentPage from "./components/InnerPage/failed/failed";
import CancelPaymentPage from "./components/InnerPage/cancel/cancel.js";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/commitee" element={<Commitee />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        <Route path="/contactus" element={<ContactUs />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/events" element={<AllEvent />}></Route>
        <Route path="/activities" element={<Activities />}></Route>
        <Route
          path="/activityDetails/:id"
          element={<ActivityDetails />}
        ></Route>
        <Route path="/newsDetails/:id" element={<NewsDetails />}></Route>
        <Route
          path="/upcomingEventDetails/:id"
          element={<UpcomingEventDetails />}
        ></Route>
        <Route path="/news" element={<News />}></Route>
        <Route path="/eventdetails/:id" element={<EventDetails />}></Route>
        <Route path="/room" element={<Room />}></Route>
        <Route path="/roombooking/:id" element={<RoomBooking />}></Route>
        <Route path="/roombooking" element={<RoomBooking />}></Route>
        <Route path="/gallery" element={<Gallery />}></Route>
        <Route path="/puja" element={<Pooja />}></Route>
        <Route path="/donate" element={<Donation />}></Route>
        <Route path="/pujabooking/:id" element={<PoojaBooking />}></Route>
        <Route
          path="/termsandcondition"
          element={<TermsandCondition />}
        ></Route>
        <Route path="/userpolicy" element={<UserPolicy />}></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/stuti" element={<Stuti />}></Route>
        <Route path="/live-darshan" element={<LiveDarshan />}></Route>
        <Route path="/failed" element={<FailedPaymentPage />}></Route>
        <Route path="/success/:id" element={<SuccessPage />}></Route>
        <Route path="/cancel" element={<CancelPaymentPage />}></Route>
      </Routes>
      <ScrollToTop />
    </>
  );
}

export default App;
