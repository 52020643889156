import axios from "axios";

const fetchData = async (method, url, header, body) => {
  // console.log("aaa",localStorage.getItem("Wtempid")!=null ? localStorage.getItem("Wtempid") :"")
  return await axios({
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json",
      "api-key" : "ab6us9khd9ks5gn9kh3uiopw7sjk2ib5nj8kdtcng7hnc5dmkh7s4c71n2m3v4jh",
      "tempid":localStorage.getItem("Wtempid")!=null ? localStorage.getItem("Wtempid") :"",
      ...header,
    },
    data: body,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export default fetchData;


