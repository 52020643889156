import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './footer.css';
import { CgFacebook } from "react-icons/cg";
import { TiSocialTwitter } from "react-icons/ti";
import { AiFillYoutube } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { get_social_links } from "../../common/serverUrl";
import fetchData from "../../common/fetchData";

const FooterPage = () => {
  const [instaData, setInstaData] = useState();
  const [youtubeData, setYoutubeData] = useState();
  const [emailData, setEmailData] = useState();
  const [facebookData, setFaceBookData] = useState();
  const [twitterData, setTwitterData] = useState();
  useEffect(() => {
    getSocialLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSocialLink = async () => {
    const sociallink = await fetchData(
      "post",
      get_social_links, "",
    );
    // console.log(sociallink);
    if (sociallink.code === "1") {
      // console.log(sociallink.data.live);
      setInstaData(sociallink.data.instagram);
      setTwitterData(sociallink.data.twitter);
      setEmailData(sociallink.data.gmail);
      setYoutubeData(sociallink.data.youtube);
      setFaceBookData(sociallink.data.facebook);
    }
    else {
      console.log("not found");
    }
  };
  return (
    <footer className="">
      <div className="">
        <div className="container">
          <div className="footer-space">
            <div className="row col-lg-12 footer-align">
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 footer-widget align-self-center">
                <Link to='/'>
                  <img src="https://ghelasomnath.org/assets/img/logo-new.png" alt='' style={{ height: "8rem" }} />
                </Link>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
                <h5 className='fs-white'>Contact Detail</h5>
                <ul>
                  <li>
                    <i className="far fa-phone custom-primary me-3 flex align-self-center"></i>
                    <p className="ft">99799 24986</p>
                  </li>
                  <li>
                    <i className="far fa-envelope custom-primary me-3 flex align-self-center"></i>
                    <p className="ft" style={{ cursor: 'pointer' }} onClick={() => window.location = 'mailto:' + emailData}>{emailData}</p>
                  </li>
                  <li>
                    <i className="far fa-map-marker custom-primary me-3 mt-2 fs-6"></i>
                    <p className="ft">Sompipaliya,
                      Vichiya,<br />
                      Jasdan,
                      Rajkot ,<br />
                      Gujarat, 360050</p>
                  </li>
                  <div>
                    <div className="footer-social mt-2">
                      {/* <a
                        href={facebookData}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CgFacebook />
                      </a> */}
                      {/* <a
                        href={twitterData}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'white' }}
                      >
                        <TiSocialTwitter />
                      </a> */}
                      <a
                        href={youtubeData}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiFillYoutube />
                      </a>
                      {/* <a
                        href={instaData}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <BsInstagram />
                      </a> */}
                    </div>
                  </div>
                </ul>
              </div>
              <div className="col-xl-3 col-lg-2 col-md-3 col-sm-12 footer-widget">
                <h5 className='fs-white'>Information</h5>
                <ul>
                  <li>
                    <Link to='/termsandcondition' >
                      Terms and Condition
                    </Link>
                  </li>
                  <li>
                    <Link to='/userpolicy' >
                      User policy
                    </Link>
                  </li>
                  <li>
                    <Link to='/privacypolicy' >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to='/contactus' >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 footer-widget">
                <h5 className='fs-white'>Others</h5>
                <ul>
                  <li>
                    <Link to='/puja' >
                      Puja
                    </Link>
                  </li>
                  <li>
                    <Link to='/stuti'>
                      Stuti
                    </Link>
                  </li>
                  <li>
                    <Link to='/commitee' >
                      Commitee
                    </Link>
                  </li>
                  <li>
                    <Link to='/donate' >
                      Donation
                    </Link>
                  </li>

                </ul>
              </div>

              <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 footer-widget">
                <h5 className='fs-white'>Map</h5>
                <div>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3697.58885241426!2d71.36513822515926!3d22.065317779856255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3958f03b084f2391%3A0xf4175b6aabe4bbf5!2sGhela%20Somnath%2C%20Gujarat%20360055!5e0!3m2!1sen!2sin!4v1688641999029!5m2!1sen!2sin" title='ghelasomnath' style={{ height: "12rem", width: "100%" }}></iframe>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default FooterPage
