import React, { useState } from 'react';
import { IoMdArrowDropupCircle } from "react-icons/io";
import './ScrollToTop.css';

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);
    return (
        <div>
            <button className='scroll-btn'>
                <IoMdArrowDropupCircle onClick={scrollToTop}
                    style={{ display: visible ? 'block' : 'none' }} />
            </button>
        </div>
    )
}

export default ScrollToTop
