import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './gallery.css';
import Header from '../../Header/Header';
import { get_client_gallery } from "../../../common/serverUrl";
import FooterPage from "../../Footer/FooterPage";
import fetchData from "../../../common/fetchData";
import { CgClose } from "react-icons/cg";
// import { BarWave } from "react-cssfx-loading";
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { de } from '@fancyapps/ui/dist/fancybox/l10n/de.esm.js';

Fancybox.bind('[data-fancybox="gallery"]', {
    l10n: de,
});

const Gallery = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedTabData, setSelectedTabData] = useState({});
    const [galleryData, setGalleryData] = useState();
    const [tempimgsrc, setTempImgSrc] = useState('');
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        getGallary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getGallary = async () => {
        const gallery = await fetchData(
            "post",
            get_client_gallery, "",
        );
        // console.log(gallery);
        if (gallery.code === "1") {
            setGalleryData(gallery.data);
            setSelectedTabData(gallery.data)
        }
        else {
            console.log("not found");
        }
    };
    // console.log(galleryData);

    const getImg = (imgsrc) => {
        // console.log(imgsrc)
        setTempImgSrc(imgsrc);
    }

    // console.log("selectedTabData", selectedTabData, selectedTabIndex)

    return (
        <>
            <Header />
            {loading ? (
                <div className="loader-container" >
                <div className="loader-icon">
                    <img src='/assets/img/logo2.png' alt="" />
                    <img src="/assets/img/loading5.gif" alt="" />
                </div>
            </div>
            ) : (
                <>
            {/* <div className="grid grid-cols-3 gap-4 max-w-xl mx-auto p-10">
                <a data-fancybox="gallery" href="https://lipsum.app/id/60/1600x1200">
                    <img className="rounded" src="https://lipsum.app/id/60/200x150" />
                </a>
                <a data-fancybox="gallery" href="https://lipsum.app/id/61/1600x1200">
                    <img className="rounded" src="https://lipsum.app/id/61/200x150" />
                </a>
                <a data-fancybox="gallery" href="https://lipsum.app/id/62/1600x1200">
                    <img className="rounded" src="https://lipsum.app/id/62/200x150" />
                </a>
            </div> */}


            <section className="innerPageHeader">
                <div className="container" >
                    <div className='px-5'>
                        <h2 className='fs-white m-0'>Gallery</h2>
                        <ol className="breadcrumb fs-6">
                            <li className="breadcrumb-item">
                                <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                                    <span className='backpath'>Home </span>
                                </Link>

                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                        </ol>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className='gallery'>
                    {galleryData && galleryData?.length > 0 ? (
                        <div className="row">
                            {galleryData?.map((item, index) => (

                                item?.images.split(",").map((item) =>
                                    <>
                                        <div className="col-lg-4 col-md-6 g2-img">
                                            <img src={"https://ghelasomnath.org/images/" + item} alt="portfolio" />
                                            <div className='overlay' data-fancybox="gallery" href={"https://ghelasomnath.org/images/" + item}>
                                                <button className="room-view-btn" data-bs-toggle="" data-bs-target="#exampleModal" onClick={() => getImg("https://ghelasomnath.org/images/" + item)}>view</button>
                                            </div>
                                            {/* <img className="rounded" src={"https://ghelasomnath.org/images/" + item} alt="portfolio" />
                                            <div className='overlay'>
                                                <button className="room-view-btn" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => getImg("https://ghelasomnath.org/images/" + item)}>view</button>
                                            </div> */}
                                        </div>
                                    </>
                                )
                            ))}
                        </div>

                    ) : (
                        <h2  >Data Not Found!{galleryData}</h2>
                    )}
                    <div className="modal fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className=" modal-dialog modal-dialog-scrollable modal-dialog-centered model-xl">
                            <div className="modal-content">
                                <img src={tempimgsrc} alt="" style={{ height: "100%", width: "100%" }} />
                            </div>
                        </div>
                        <button type="button" className="model-close" data-bs-dismiss="modal" aria-label="Close"><CgClose /></button>
                    </div>

                </div>
            </div >

            <FooterPage />
            </>
            )}
        </>
    )
}

export default Gallery
