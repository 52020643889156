import React, { useEffect, useState } from 'react';
import './room.css'
import { useParams } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
import { add_rooms_bookings, get_rooms_category_by_id, payment, check_available_rooms } from "../../../common/serverUrl";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import fetchData from "../../../common/fetchData";
import Toast from "react-bootstrap/Toast";
import Header from '../../Header/Header';
import axios from 'axios';
import moment from 'moment';
import { Country, State, City } from 'country-state-city';
import Select from "react-select";
import { PiArrowFatLinesRightFill } from "react-icons/pi";

const RoomBooking = () => {

	let { id } = useParams();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedState, setSelectedState] = useState(null);
	const [selectedCity, setSelectedCity] = useState(null);
	const [Name, setName] = useState("");
	const [Phone, setPhone] = useState("");
	const [Email, setEmail] = useState("");
	const [RoomCategory, setRoomCategory] = useState("");
	const [TotalRooms, setTotalRooms] = useState("");
	const [MaxRooms, setMaxRoom] = useState("");
	const [TotalPerson, setTotalPerson] = useState("");
	const [RoomsPrice, setRoomsPrice] = useState("");
	const [CheckInDate, setCheckInDate] = useState();
	const [CheckOutDate, setCheckOutDate] = useState("");
	const [isError, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [Address, setAddress] = useState("");
	const [roomNumbers, setRoomNumbers] = useState("");
	const [Zip, setZip] = useState("");
	const [Image, setImage] = useState(null);
	const [extraBed, setExtraBed] = useState();
	const [totalDays, setTotalDays] = useState(1);
	const [selectedRooms, setSelectedRooms] = useState([]);
	const [availableRooms, setAvailableRooms] = useState([]);
	const [selectedRoomsLength, setSelectedRoomsLength] = useState();
	const checkin_date = moment(CheckInDate);
	const checkout_date = moment(CheckOutDate);
	const diff = checkout_date.diff(checkin_date);
	const diffDuration = moment.duration(diff);
	// console.log("Days:", diffDuration.days());
	const days = diffDuration.days();

	const phoneNumber = event => {
		const limit = 10;
		setPhone(event.target.value.slice(0, limit));
	};



	var asyncLoop = require('node-async-loop');
	function handleImage(e) {
		const selectedFIles = [];
		if (e.target.files && e.target.files[0]) {
			if (e.target.files[0].size > 1 * 1000 * 1024) {
				Swal.fire({
					position: 'top-center',
					icon: 'error',
					title: "File size not allowed to Graterthan 1MB",
					toast: true,
					showConfirmButton: false,
					timer: 4000
				})
				return false;
			}
		}
		const targetFiles = e.target.files;
		const targetFilesObject = [...targetFiles];
		targetFilesObject.map((file) => {
			return selectedFIles.push(file)
		})
		setImage(selectedFIles);
	}

	useEffect(() => {
		setTotalDays(days);

	}, [days]);

	// console.log("selectedRoomsLength", selectedRoomsLength);
	// console.log("roomprice", RoomsPrice);

	useEffect(() => {
		if (CheckOutDate < CheckInDate) {
			Swal.fire({
				position: 'top-center',
				icon: 'error',
				title: "Please check your Dates",
				toast: true,
				showConfirmButton: false,
				timer: 2000
			})
		}
		else {
			available_rooms1();
		}

	}, [CheckOutDate]);

	const available_rooms1 = async () => {

		const available_rooms = await fetchData(
			"post", check_available_rooms, "",
			{
				checkin_date: CheckInDate,
				checkout_date: CheckOutDate,
				room_category: RoomCategory
			}
		);
		//console.log(roomNumbers)
		//console.log(available_rooms.data)
		if (available_rooms.code === "1") {
			var rooms = roomNumbers.split(",")
			// console.log(available_rooms.data.length, "iiizzzz")
			if (available_rooms.data.length > 0) {
				// console.log(available_rooms.data.split(","), "kkkk")
				rooms = rooms.filter(val => !available_rooms.data.split(",").includes(val));
				// console.log(rooms, "iiiiiiiiiiiiiiii")
				setAvailableRooms(rooms)
			}
			else {
				setAvailableRooms(rooms)
			}
		}
		else {
			setAvailableRooms([])
		}
	}

	// console.log("available rooms ", availableRooms);
	// console.log(Image)
	// console.log(id)

	useEffect(() => {
	
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
		getRooms();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const disablePastDate = () => {
		const today = new Date();
		const dd = String(today.getDate() + 1).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = today.getFullYear();

		return yyyy + "-" + mm + "-" + dd;
	};

	const getRooms = async () => {

		const rooms = await fetchData(
			"post",
			get_rooms_category_by_id, "",
			{
				id: id
			}
		);
		// console.log(rooms.data, "kkkk");
		if (rooms.code === "1") {
			// console.log("total rooms", rooms.data.room_numbers);
			setRoomNumbers(rooms.data.room_numbers)
			setRoomsPrice(rooms.data.price);
			setMaxRoom(rooms.data.rooms);
			setTotalRooms(rooms.data.rooms);
			setRoomCategory(rooms.data.name);
		}
		else {
			console.log("not found");
		}
	};
	// console.log(roomNumbers)
	function selectPermission(e) {
		//console.log("selc room id",e.target.id)
		if (e.target.checked) {
			// console.log(e.target.id)
			selectedRooms.push(e.target.id);
			setSelectedRooms(selectedRooms)
		} else {
			//document.getElementById(e.target.id).style.backgroundColor="gray";
			let index = selectedRooms.indexOf(e.target.id);
			selectedRooms.splice(index, 1);
			setSelectedRooms(selectedRooms)
		}
		setSelectedRoomsLength(selectedRooms.length)
	}

	// console.log("selected rooms", selectedRoomsLength);
	const onSubmit = async (event) => {

		event.preventDefault();

		var imageArray = [];
		if (Image == null) {
			Swal.fire({
				position: 'top-center',
				icon: 'warning',
				title: "Please Upload Your Id Proof",
				toast: true,
				showConfirmButton: false,
				timer: 2000
			})
		}
		if (selectedRoomsLength == undefined) {
			Swal.fire({
				position: 'top-center',
				icon: 'warning',
				title: "Please Select Room",
				toast: true,
				showConfirmButton: false,
				timer: 2000
			})
		}
		else {
			asyncLoop(Image, function (item, next) {

				const formData = new FormData();
				formData.append("personal_id_image", item);
				// console.log("imge item", formData)
				axios.post("https://ghelasomnath.org:8081/api/v1/user/imageUpload", formData).then((res) => {
					imageArray = res.data.data
					next();
				})
			}, function () {
				fetchData(
					"post",
					add_rooms_bookings,
					" ",
					{
						name: Name,
						phone: Phone,
						room_category: RoomCategory,
						number_of_rooms: selectedRooms.toString(),
						total_person: TotalPerson,
						total_days: totalDays,
						is_extra_bed: extraBed,
						checkin_date: CheckInDate,
						checkout_date: CheckOutDate,
						payment_amount: extraBed ? (extraBed * 200) + RoomsPrice * totalDays * selectedRoomsLength : RoomsPrice * totalDays * selectedRoomsLength,
						payment_status: "Pending",
						email: Email,
						city: selectedCity.name,
						state: selectedState.name,
						country: selectedCountry.name,
						address: Address,
						zip: Zip,
						personal_id_image: imageArray
					}
				).then((response) => {
					if (response.code === "1") {
						// console.log("pooja success",response.data)
						fetchData(
							"get",
							payment,
							" ",
							{}
						).then((responsee) => {
							const formBlob = new Blob([responsee], { type: 'text/html' }); // Adjust the MIME type according to your form data
							// Create a URL for the Blob object
							const formUrl = URL.createObjectURL(formBlob);
							// Open the form in a new tab
							window.open(formUrl, '_self');
							// console.log("--------->>", responsee)
						});
					}
				});
			})
		}
	}

	return (
		<div>
			<Header />
			{loading ? (
				<div className="loader-container" >
					<div className="loader-icon">
						<img src='/assets/img/logo2.png' alt="" />
						<img src="/assets/img/loading5.gif" alt="" />
					</div>
				</div>
			) : (
				<>
					<section className="innerPageHeader">
						<div className="container" >
							<div className='px-5'>
								<h1 className='fs-white m-0 font'>Room Booking</h1>
								<ol className="breadcrumb fs-6">
									<li className="breadcrumb-item">
										<Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
											<span className='backpath'>Home </span>
										</Link>
									</li>
									<li className="breadcrumb-item">
										<Link to='/room' className={`${location.pathname === "/room" ? "btn-link" : ""}`}>
											<span className='backpath'>Room</span>
										</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">RoomBooking</li>
								</ol>
							</div>
						</div>
					</section>

					{isError && message !== "" && (
						<ToastContainer className="p-3" position="top-center">
							<Toast>
								<Toast.Header>
									<img
										src="holder.js/20x20?text=%20"
										className="rounded me-2"
										alt=""
									/>
									<strong className="me-auto" style={{ color: "red" }}>
										{isError ? "Error" : "Success"}
									</strong>
								</Toast.Header>
								<Toast.Body>{message}</Toast.Body>
							</Toast>
						</ToastContainer>
					)}

					<div style={{ backgroundImage: "url(https://ghelasomnath.org/assets/img/events/bg-grey.png)" }}>
						<div className="container">
							{
								<form onSubmit={onSubmit}>
									<div className="row roombooking">

										<div className="col-lg-6 col-md-12">
											<div className="form-group">
												<i className="far fa-user"></i>
												<input type="text" className="form-control" onChange={(e) => setName(e.target.value)} required value={Name} placeholder="Name" name="name" />
											</div>
										</div>

										<div className="col-lg-6">
											<div className="form-group">
												<i className="far fa-envelope"></i>
												<input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)}
													required name="email" placeholder="Please enter email" />
											</div>
										</div>

										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<i className="far fa-phone"></i>
												<input type="number" title="please enter number only" className="form-control" onChange={phoneNumber} required value={Phone} placeholder="Phone" name="phone" />
											</div>
										</div>

										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<i className="far fa-users"></i>
												<input type="number" className="form-control" onChange={(e) => setTotalPerson(e.target.value)}
													required max={selectedRoomsLength ? selectedRoomsLength * 2 : 2} value={TotalPerson} placeholder="Total Person" name="totalperson" />
											</div>
										</div>

										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<i className="far fa-pencil"></i>
												<input type="text" className="form-control" readonly="readonly" onChange={(e) => setRoomCategory(e.target.value)}
													required value={RoomCategory} name="roomcategory" style={{ backgroundColor: "white" }} />
											</div>
											
													<div className="form-group">
														<input type="date" onfocus="(this.type='date')" name="check_in_date" className="form-control" onChange={(e) => setCheckInDate(e.target.value)}
															required value={CheckInDate} min={disablePastDate()} placeholder="Check In Date" />
													</div>

													<div className="form-group">
														<input type="date" onfocus="(this.type='date')" name="check_out_date" className="form-control" onChange={(e) => setCheckOutDate(e.target.value)}
															required value={CheckOutDate} min={disablePastDate()} placeholder="Check Out Date" />
													</div>

													<div className="form-group">
														<label className='mb-1' style={{ marginLeft: "1rem" }}>Available Rooms </label>


														<div className='form-control align-self-center' style={{ height: 'fit-content' }}>

															{availableRooms == false && (
																<div style={{ fontSize: '15px', color: '#bcb5b5' }}>Pleace select Check-In & Check-Out date for view available rooms</div>
															)}

															{availableRooms && availableRooms.map((item) =>
																<>
																	<input type="checkbox" id={item} value={item} rows="4" onChange={selectPermission} style={{ height: "25px", width: "25px", overflow: 'hidden' }} />
																	<label htmlFor={item}>{item}</label>
																</>
															)}
														</div>

														<div>
															{availableRooms?.length < 0 &&
																<>
																	<h3>No Room Available</h3>
																</>
															}
														</div>
													</div>
											<div className="form-group">
												<i className="far fa-image" style={{ paddingTop: "2rem" }}></i>
												<label for="formFileLg" className='mb-1' style={{ paddingLeft: "1rem" }}>Upload Your Id Proof <span style={{ color: "red", fontSize: "12px" }}>(max file size 1 MB.)</span></label>
												<input className="form-control" id="formFileLg" type="file" placeholder="Add File" style={{ backgroundColor: "white", paddingLeft: "2rem", paddingTop: "1rem" }} accept="image/*" onChange={handleImage} />
											</div>

										</div>

										<div className="col-lg-6 col-md-6">
											<div className="form-group">
												<Select
													className="form-control"
													placeholder="Select Country"
													options={Country.getAllCountries()}
													getOptionLabel={(options) => {
														return options["name"];
													}}
													getOptionValue={(options) => {
														return options["name"];
													}}
													value={selectedCountry}
													onChange={(item) => {
														setSelectedCountry(item);
													}}
												/>
											</div>

											<div className="form-group">
												<Select
													className="form-control"
													placeholder="Select State"
													options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
													getOptionLabel={(options) => {
														return options["name"];
													}}
													getOptionValue={(options) => {
														return options["name"];
													}}
													value={selectedState}
													onChange={(item) => {
														setSelectedState(item);
													}}
												/>
											</div>

											<div className="form-group">
												<Select className="form-control"
													placeholder="Select City"
													options={City.getCitiesOfState(
														selectedState?.countryCode,
														selectedState?.isoCode
													)}
													getOptionLabel={(options) => {
														return options["name"];
													}}
													getOptionValue={(options) => {
														return options["name"];
													}}
													value={selectedCity}
													onChange={(item) => {
														setSelectedCity(item);
													}}
												/>
											</div>

											<div className="form-group">
												<i className="far fa-file"></i>
												<input type="text" name="zip" className="form-control" onChange={(e) => setZip(e.target.value)} required placeholder="Pincode" />
											</div>

											<div className="form-group">
												<i className="far fa-home"></i>
												<textarea type="text" name="address" className="form-control" placeholder="Address" rows="5" onChange={(e) => setAddress(e.target.value)} value={Address} />
											</div>
										</div>

										{
											(id == 1 || id == 5 || id == 2) &&
											<div className="col-lg-6 col-md-12">
												<div className="form-group">
													<i className="far fa-bed"></i>
													<input type="number" name="extra_beds" className="form-control" placeholder="Extra Beds" onChange={(e) => setExtraBed(e.target.value)} />
												</div>
											</div>
										}

										<div className="col-lg-6">
											<div className="form-group">
												<i className="far  fa-rupee-sign"></i>
												<input type="number" name="amount" readonly="readonly" className="form-control" onChange={(e) => setRoomsPrice(e.target.value)} required value={extraBed ? (extraBed * 200 * totalDays) + RoomsPrice * totalDays * selectedRoomsLength : RoomsPrice * totalDays * selectedRoomsLength} placeholder="Amount" rows="4" style={{ backgroundColor: "white" }} />
											</div>
										</div>

										<div className="d-flex justify-content-center">
											<button type="submit" className="common-btn" name="button"> Booking <PiArrowFatLinesRightFill className='fs-4' /> </button>
										</div>
									</div>
								</form>
							}
						</div>
					</div>
					<FooterPage />
				</>
			)
			}
		</div >
	)
}

export default RoomBooking
