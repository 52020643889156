import React, { useEffect, useState } from "react";
import { add_query } from "../../../common/serverUrl";
import { useNavigate } from "react-router-dom";
import fetchData from "../../../common/fetchData";
import Header from '../../Header/Header';
import { Link, useLocation } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
import 'react-toastify/dist/ReactToastify.css';
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import Swal from 'sweetalert2';
import './contactUs.css'

const ContactUs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Email, setEmail] = useState("");
    const [Query, setQuery] = useState("");
    const [QuerySubject, setQuerySubject] = useState(false);
    const [isError, setError] = useState(false);
    const [message, setMessage] = useState("");
    const onSubmit = async (event) => {
        event.preventDefault();
        const response = await fetchData(
            "post",
            add_query,
            " ",
            {
                first_name: FirstName,
                last_name: LastName,
                query_subject: QuerySubject,
                query: Query,
                email: Email
            }
        );
        // console.log(response);
        if (response.code === "1") {
            Swal.fire({
                position: 'top-center',
                title: "અમારો સંપર્ક કરવા બદલ આભાર! અમે તમારા પ્રશ્ન નો ઉકેલ લાવવાનો પ્રયત્ન કરીશું",
                toast: true,
                showConfirmButton: false,
                timer: 4000
            });
            setTimeout(() => {
                navigate("/");
            }, 3000);
        } else {
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                title: response.message,
                toast: true,
                showConfirmButton: false,
                timer: 4000
            });
            setError(true);
            setMessage(response.message);
            setTimeout(() => {
                setError(false);
                setMessage("");
            }, 2000);
        }
    };
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <div>
            <Header />
            {loading ? (
                <div className="loader-container" >
                    <div className="loader-icon">
                        <img src='/assets/img/logo2.png' alt="" />
                        <img src="/assets/img/loading5.gif" alt="" />
                    </div>
                </div>
            ) : (
                <>
                    <section className="innerPageHeader">
                        <div className="container" >
                            <div className='px-5'>
                                <h2 className='fs-white m-0 font'>Contact Us</h2>
                                <ol className="breadcrumb fs-6">
                                    <li className="breadcrumb-item">
                                        <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                                            <span className='backpath'>Home </span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">contact Us</li>
                                </ol>
                            </div>
                        </div>
                    </section>
                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3697.5887223618756!2d71.36513822420284!3d22.065322751373618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3958f03b084f2391%3A0xf4175b6aabe4bbf5!2sGhela%20Somnath%2C%20Gujarat%20360055!5e0!3m2!1sen!2sin!4v1681363445101!5m2!1sen!2sin"
                            title="ghelsomnath-map" className="contact-map">
                        </iframe>
                    </div>

                    <div className="contact-form">
                        <div className="container">

                            <form onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <i className="far fa-user"></i>
                                            <input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} required className="form-control dark" name="name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <i className="far fa-user"></i>
                                            <input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} required className="form-control dark" name="name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <i className="far fa-envelope"></i>
                                            <input type="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} required className="form-control dark" name="email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <i className="far fa-pencil"></i>
                                            <input type="text" placeholder="Query Subject" onChange={(e) => setQuerySubject(e.target.value)} required className="form-control dark" name="Subesubject" />
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group">
                                            <input type="text" placeholder="Please Enter Query" onChange={(e) => setQuery(e.target.value)} required cols="45" rows="5" className="form-control dark" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button type="submit" className="common-btn" name="button"> Submit <PiArrowFatLinesRightFill className='fs-4' /> </button>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div className="p-3" style={{ backgroundImage: "url(https://ghelasomnath.org/assets/img/events/bg-grey.png)" }}>
                        <div className="container">
                            <div className="row contact-card">

                                <div className="col-lg-4">
                                    <div className="sigma_icon-block text-center light icon-block-7">
                                        <i className="flaticon-email"></i>
                                        <div className="sigma_icon-block-content">
                                            <span>Send Email <i className="far fa-arrow-right"></i> </span>
                                            <h5> Email Address</h5>
                                            <p>info@ghelasomnath.org</p>
                                        </div>
                                        <div className="icon-wrapper">
                                            <i className="flaticon-email"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="sigma_icon-block text-center light icon-block-7">
                                        <i className="flaticon-call"></i>
                                        <div className="sigma_icon-block-content">
                                            <span>Call Us Now <i className="far fa-arrow-right"></i> </span>
                                            <h5> Phone Number </h5>
                                            <p>  99799 24986 </p>
                                        </div>
                                        <div className="icon-wrapper">
                                            <i className="flaticon-call"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="sigma_icon-block text-center light icon-block-7">
                                        <i className="flaticon-location"></i>
                                        <div className="sigma_icon-block-content">
                                            <span>Find Us Here <i className="far fa-arrow-right"></i> </span>
                                            <h5> Location </h5>
                                            <p>Sompipaliya,Vichiya,Jasdan,Rajkot</p>
                                            <p>Gujarat,360050</p>
                                        </div>
                                        <div className="icon-wrapper">
                                            <i className="flaticon-location"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <FooterPage />
                </>
            )}
        </div>
    )
}

export default ContactUs
