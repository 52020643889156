import React from 'react';
import './About.css';

const DailySchedule = () => {
    return (
        <div>
            <section className='dailySchedule-space'>
                <div>
                    <div className="row">
                        <div className="col-lg-7 col-md-6 d-flex flex-column gap-3 align-self-center">
                            <h1>Daily Schedule</h1>
                            <p className='text-muted'>Temple is open every day from 5.00 A.M. to 9.00 P.M. Shravan month is the best time to visit Ghela Somnath Mandir.</p>
                            <div>
                                <table className='schedule-table'>
                                    <tbody>
                                        <tr>
                                            <td>પુજાનો સમય </td>
                                            <td>સવારે ૬-૦૦ થી ૯-૩૦ સુધી / બપોરે ૩-૩૦ થી ૪-૩૦ સુધી</td>
                                        </tr>
                                        <tr>
                                            <td>આરતી સમય </td>
                                            <td>સવારે ૫-૩૦ કલાકે / બપોરે ૧૨-૩૦ કલાકે / સાંજે ૭-૦૦ કલાકે</td>
                                        </tr>
                                        <tr>
                                            <td>ભોજન પાસ લેવાનો સમય</td>
                                            <td>બપોરે ૧૨-૦૦ થી ૧૨-૩૦ સુધી  / સાંજે ૭-૦૦ થી ૭-૩૦ સુધી</td>
                                        </tr>
                                        <tr>
                                            <td>ભોજન સમય </td>
                                            <td>બપોરે ૧૨-૩૦ થી ૧-૩૦ સુધી  / સાંજે ૭-૩૦ થી ૮-૩૦ સુધી</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 pt-2">
                            <img className='schedule-img' src="https://ghelasomnath.org/assets/img/about/gate2.jpg" alt="ghelasomnath gate" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DailySchedule
