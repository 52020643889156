import React from 'react';
import './TempleVideo.css';

const TempleVideo = () => {
    return (
        <>
            <div className='main'>
                <div className="overlay"></div>
                <video src='https://ghelasomnath.org/assets/video/home2.mp4' autoPlay loop muted />
                
                <div className="homecontent">
                    <img className='mb-4' src="https://ghelasomnath.org/assets/img/logo2.png" style={{ "height": "90x", "width": "130px" }} alt="logo" />
                    
                    <h1 className='fs-white' style={{textShadow: '2px 2px #272e38, 0 0 5px #0a1b34'}}>Ghela Somnath</h1>
                </div>
            </div>
        </>
    )
}

export default TempleVideo
