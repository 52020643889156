import React, { useEffect, useState } from "react";
import "./blog.css"
import { get_social_links } from "../../../common/serverUrl";
import fetchData from "../../../common/fetchData";

const Blog = () => {
  const [liveData, setLiveData] = useState();
  useEffect(() => {
    getSocialLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSocialLink = async () => {
    const live_link = await fetchData(
      "post",
      get_social_links, "",
    );
    // console.log(live_link);
    if (live_link.code === "1") {
      // console.log(live_link.data.live);
      setLiveData(live_link.data.live);
    }
    else {
      console.log("not found");
    }
  };
  // console.log("live link",liveData)
  return (
    <div className="" style={{ backgroundImage: "url(https://ghelasomnath.org/assets/img/events/bg-grey.png)" }}>
      <div className="container">
        <div className='blog' style={{}}>
          <div className='text-center '>
            <h1>Welcome to <br /> Ghela Somnath</h1>
            <p className='blog-para text-muted' >The Ghela Somnath is the temple of Lord Shiva situated at Jasdan, Gujarat. The River Ghela is a waterway that goes through the town of Gadhada in the Indian province of Gujarat. Ghelo stream starts from Jasdan slopes (Around Fulzar town, Jasdan Taluka) and meets in the Arabian ocean. Its length is 118 km. and 622 km2. catchment region.</p>
          </div>
          <div>
            <iframe className="blog-video col-12" src={liveData} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog
