import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
import Header from '../../Header/Header';

function PrivacyPolicy() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      <Header />
      {loading ? (
        <div className="loader-container" >
        <div className="loader-icon">
            <img src='/assets/img/logo2.png' alt="" />
            <img src="/assets/img/loading5.gif" alt="" />
        </div>
    </div>
      ) : (
        <>
          <section className="innerPageHeader">
            <div className="container" >
              <div className='px-5'>
                <h2 className='fs-white m-0'>Privacy Policy</h2>
                <ol className="breadcrumb fs-6">
                  <li className="breadcrumb-item">
                    <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                      <span className='backpath'>Home </span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                </ol>
              </div>
            </div>
          </section>
          <div className='starting-space'>
            <div className="container">
              <h3>Privacy Policy</h3>
              <p className="mt-3" style={{textAlign:"justify"}}>
                PRIVACY
                [Business Name] believes strongly in protecting user privacy and providing you with
                notice of MuscleUP Nutrition 's use of data. Please refer to [Business Name] privacy
                policy, incorporated by reference herein, that is posted on the Website.
              </p>
            </div>
          </div>
          <FooterPage />
        </>
      )}
    </div>
  )
}
export default PrivacyPolicy