import React, { useEffect, useState } from "react";
import FooterPage from "../../Footer/FooterPage";
import Header from "../../Header/Header";
import "./failed.css";
export default function Success() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      <Header />
      {loading ? (
        <div className="loader-container">
          <div className="loader-icon">
            <img src="/assets/img/logo2.png" alt="" />
            <img src="/assets/img/loading5.gif" alt="" />
          </div>
        </div>
      ) : (
        <>
          <div
            className="text-center starting-space"
            style={{
              backgroundImage:
                "url(https://ghelasomnath.org/assets/img/events/bg-grey.png)",
            }}
          >
            <div>
              <img
                src="https://ghelasomnath.org/assets/img/payment_failed.png"
                alt="payment-failed"
                style={{ height: "15rem" }}
              />
              <h3 style={{ color: "#f16f23" }}>
                SORRY!
                <span style={{ fontSize: "25px", fontStyle: "italic" }}>
                  {" "}
                  YOUR PAYMENT HAS BEEN FAILED
                </span>{" "}
              </h3>
            </div>
          </div>
          <FooterPage />
        </>
      )}
    </div>
  );
}
