import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get_clientUpcomingEvnets } from "../../../common/serverUrl";
import fetchData from "../../../common/fetchData";
import { Link, useLocation } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
import Header from "../../Header/Header";
import { IoLocationSharp } from "react-icons/io5";
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import { CgClose } from "react-icons/cg";
import "./Events.css";

const UpcomingEventDetails = () => {
  let { id } = useParams();

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [upcomingeventsImage, setupcomingeventsImage] = useState();
  const [upcomingeventsName, setupcomingeventsName] = useState();
  const [upcomingeventsDay, setEventNewsActivitieDay] = useState();
  const [upcomingeventsDate, setEventNewsActivitieDate] = useState();
  const [upcomingeventsTime, setEventNewsActivitieTime] = useState();
  const [upcomingeventsDescription, setupcomingeventsDescription] = useState();
  const [upcomingeventsPlace, setupcomingeventsPlace] = useState();
  const [tempimgsrc, setTempImgSrc] = useState('');


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    getupcomingevents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getupcomingevents = async () => {
    const upcomingevents = await fetchData(
      "post",
      get_clientUpcomingEvnets,
      "",
      {
        id: id,
      }
    );
    if (upcomingevents.code === "1") {
      // console.log(upcomingevents.data[0]);
      setupcomingeventsImage(upcomingevents.data[0].photo);
      setupcomingeventsName(upcomingevents.data[0].type);
      setEventNewsActivitieDay(upcomingevents.data[0].day);
      setEventNewsActivitieTime(upcomingevents.data[0].time);
      setEventNewsActivitieDate(upcomingevents.data[0].date);
      setupcomingeventsDescription(
        upcomingevents.data[0].description.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi,'')
      );
      setupcomingeventsPlace(upcomingevents.data[0].place);
    } else {
      console.log("not found");
    }
  };

  const date = new Date(upcomingeventsDate)
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric"
  })

  const getImg = (imgsrc) => {
    console.log(imgsrc)
    setTempImgSrc(imgsrc);
  }


  return (
    <div>
      <Header />
      {loading ? (
        <div className="loader-container">
          <div className="loader-icon">
            <img src="/assets/img/logo2.png" alt="" />
            <img src="/assets/img/loading5.gif" alt="" />
          </div>
        </div>
      ) : (
        <>
          <section className="innerPageHeader">
            <div className="container">
              <div className="px-5">
                <h2 className="fs-white m-0 font">UpcomigEvent Details</h2>
                <ol className="breadcrumb fs-6">
                  <li className="breadcrumb-item">
                    <Link
                      to="/"
                      className={`${location.pathname === "/" ? "btn-link" : ""
                        }`}
                    >
                      <span className="backpath">Home </span>
                    </Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    UpcomingEventDetails
                  </li>
                </ol>
              </div>
            </div>
          </section>

          <div className="starting-space">
            <div className="container">
              <div className="event-detail-space">
                <div className="card mb-3 ">
                  <div className="row g-0">
                    <div className="col-md-5 ed-img">
                      <img
                        src={
                          "https://ghelasomnath.org/images/" +
                          upcomingeventsImage
                        }
                        className="img-fluid rounded-start"
                        style={{ height: "100%", cursor: 'pointer' }}
                        alt="ghelasomnath upcoming-event"
                        data-bs-toggle="modal" data-bs-target="#upcomingeventImg" onClick={() => getImg("https://ghelasomnath.org/images/" + upcomingeventsImage)}
                      />

                    </div>

                    <div className="col-md-7">
                      <div className="card-body">
                        <h5 className="card-title">
                          <Link to="event-details.html"></Link>
                          {upcomingeventsName}
                        </h5>

                        <p className="card-text">{upcomingeventsName}</p>

                        <div className="d-flex flex-column gap-2">
                          <Link to="">
                            {" "}
                            {upcomingeventsTime
                              ? "🕔 " + upcomingeventsTime
                              : ""}{" "}
                          </Link>
                          <Link to="">
                            {upcomingeventsDay ? "🕔 " + upcomingeventsDay : ""}{" "}
                          </Link>
                          <Link to="">
                            {upcomingeventsDate
                              ? "🕔 " + formattedDate
                              : ""}{" "}
                          </Link>
                          <Link to="">
                            <IoLocationSharp className="event-location" />{" "}
                            {upcomingeventsPlace}{" "}
                          </Link>
                          <p className="text-muted" style={{textAlign:"justify"}}>
                            <PiArrowFatLinesRightFill className="fs-4" />
                            &nbsp;&nbsp;{upcomingeventsDescription}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="img-modal">
            <div className="modal fade " id="upcomingeventImg" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className=" modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <img src={tempimgsrc} alt="" />
                </div>
              </div>
              <button type="button" className="model-close" data-bs-dismiss="modal" aria-label="Close"><CgClose /></button>
            </div>
          </div>

          <FooterPage />
        </>
      )}
    </div>
  );
};

export default UpcomingEventDetails;
