import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
// import FeatherIcon from "feather-icons-react";
import { FiDownload } from "react-icons/fi";
import Header from "../../Header/Header";
import fetchData from "../../../common/fetchData";
import {
  send_email_donation,
  send_email_pooja,
  send_email_room,
} from "../../../common/serverUrl";

export default function Success() {
  const [response, setResponse] = useState(false);
  const [type, setType] = useState(false);

  useEffect(() => {
    getSendMail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { id } = useParams();
  const download_pdf = "https://ghelasomnath.org/pdf/" + id + ".pdf";
  // const download_pdf = "A:/pdf/" + id + ".pdf";
  const fileName = id + ".pdf";
  const onButtonClick = (url) => {
    // console.log(fileName);
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  const getSendMail = async () => {
    console.log("kokoko");
    var orderType = id.slice(0, 2);
    var number1 = id.slice(4);
    console.log(number1);
    if (orderType == "DN") {
      setType(
        "શ્રી ઘેલા સોમનાથ મંદિર માટે તમારા દાન બદલ આભાર. અમે તમને દાતા તરીકે મેળવીને ધન્યતા અનુભવીએ છીએ"
      );
      const emailDataDonation = await fetchData(
        "post",
        send_email_donation,
        "",
        {
          id: number1,
        }
      );
      // console.log("emial data",emailData.code)
      if (emailDataDonation.code === "1") {
        setResponse(emailDataDonation.data);
        console.log(emailDataDonation.data);
      } else {
        console.log("not found");
      }
      //console.log(response,"iiiiiiii")
    } else if (orderType == "RM") {
      setType("તમારો રૂમ સફળતાપૂર્વક બુક થયો છે");
      const emailDataRoom = await fetchData("post", send_email_room, "", {
        id: number1,
      });
      if (emailDataRoom.code === "1") {
        setResponse(emailDataRoom.data);
        //  console.log(emailDataRoom.data);
        //setRoomsData(rooms.data);
      } else {
        console.log("not found");
      }
    } else {
      setType("તમારી પૂજા સફળતાપૂર્વક બુક થઈ ગઈ છે");
      const emailDataPooja = await fetchData("post", send_email_pooja, "", {
        id: number1,
      });
      if (emailDataPooja.code === "1") {
        setResponse(emailDataPooja.data);
        console.log(emailDataPooja.data, "data");
        // setRoomsData(rooms.data);
      } else {
        console.log("not found");
      }
    }
    // console.log(response, "pppp");
  };

  return (
    <div>
      <Header />
      <div className="text-center starting-space" style={{ margin: "20px" }}>
        <h3 style={{ color: "#f16f23" }}>{type}</h3>
        {response == false && (
          <>
            <h4 className="mt-3 mb-3">Please Wait For Receipt</h4>
            <img src="/assets/img/success-loader.gif" alt="" />
          </>
        )}
        {response && (
          <div className="downlod">
            <h4 className="mt-3 mb-3">Download Your Receipt From Here</h4>
            <h4>
              <img
                src="/assets/img/down-arrow.gif"
                style={{ height: "2.5rem", marginBottom: "0.5rem" }}
                alt=""
              />
            </h4>
            <button
              onClick={() => {
                onButtonClick(download_pdf);
              }}
              className="common-btn"
            >
              Download PDF{" "}
              {/* <FeatherIcon
                icon="download"
                width="25"
                style={{ marginLeft: "10px" }}
              /> */}
              <FiDownload
                width="25"
                style={{ marginLeft: "10px" }}
              />
            </button>
          </div>
        )}
      </div>

      <FooterPage />
    </div>
  );
}
