import React, { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import About from "./components/Layout/about/About";
import Service from "./components/Layout/Pooja/Pooja.jsx";
import BlogRoom from "./components/Layout/Blog/BlogRoom";
import QrCode from "./components/Layout/QrCode/QrCode";
import Blog from "./components/Layout/Blog/Blog.jsx";
import EventNewsActivity from "./components/Layout/Events/EventNewsActivity";
import FooterPage from "./components/Footer/FooterPage";
import CoreVision from "./components/Layout/CoreVision/CoreVision";
import VisitingPlace from "./components/Layout/visiting-place/VisitingPlace";
import TempleVideo from "./components/Layout/home/TempleVideo";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import DailySchedule from "./components/InnerPage/about/DailySchedule";

const Page = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log("hhh",localStorage.getItem("Wtempid"))
    if (localStorage.getItem("Wtempid") == null) {
      localStorage.setItem("Wtempid", Math.floor(100000 + Math.random() * 900000));
    }
  
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader-icon">
            <img src="/assets/img/logo2.png" alt="" />
            <img src="/assets/img/loading5.gif" alt="" />
          </div>
        </div>
      ) : (
        <>
          <TempleVideo />
          <Header />
          <About />
          <QrCode/>
          <Blog />
          <BlogRoom />
          <EventNewsActivity />
          <Service />
          <CoreVision />
          <DailySchedule />
          <VisitingPlace />
          <FooterPage />
          <ScrollToTop />
        </>
      )}
     
    </>
  );
};

export default Page;
