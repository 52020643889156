import React, { useEffect, useState } from "react";
import { get_eventnewsactivities } from "../../../common/serverUrl";
import { Link, useNavigate, useLocation } from "react-router-dom";
import fetchData from "../../../common/fetchData";
import Header from '../../Header/Header';
import FooterPage from "../../Footer/FooterPage";

import './Activities_News.css'

const Activities = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [eventnewsactivitiesData, setEventNewsActivitiesData] = useState();
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        getEventNewsActivities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEventNewsActivities = async () => {
        const eventnewsactivities = await fetchData(
            "post",
            get_eventnewsactivities, "",
        );
        // console.log("eventlist", eventnewsactivities);
        if (eventnewsactivities.code === "1") {
            // console.log(eventnewsactivities.data);
            setEventNewsActivitiesData(eventnewsactivities.data);
        }
        else {
            console.log("not found");
        }
    };

    const handleActivityDetails = async (id, name) => {
        navigate(`/activityDetails/${id}`);
    };
    return (
        <div>
            <Header />
            {loading ? (
                <div className="loader-container" >
                    <div className="loader-icon">
                        <img src='/assets/img/logo2.png' alt="" />
                        <img src="/assets/img/loading5.gif" alt="" />
                    </div>
                </div>
            ) : (
                <>
                    <section className="innerPageHeader">
                        <div className="container" >
                            <div className='px-5'>
                                <h2 className='fs-white m-0'>Activities</h2>
                                <ol className="breadcrumb fs-6">
                                    <li className="breadcrumb-item">
                                        <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>

                                            <span className='backpath'>Home </span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Activities</li>
                                </ol>
                            </div>
                        </div>
                    </section>
                    <div className='events-card-space'>
                        <div className="container">
                            <h1 className="text-center font"> Activities</h1>

                            {eventnewsactivitiesData && eventnewsactivitiesData?.length > 0 ? (
                                <div className="row mt-4">
                                    {eventnewsactivitiesData.map((item, index) =>
                                        item.type === "Activities" &&
                                        <>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-2 activities">
                                                <div className="card card-imgs" style={{ borderLeft: '3px solid #ffac4b', height: '25rem' }} onClick={() => handleActivityDetails(item.id)}>
                                                    <div className="activity-news-img">
                                                        <img
                                                            className="img-fluid"
                                                            src={"https://ghelasomnath.org/images/" + item?.photo}
                                                            alt="ghelasomnath activities"
                                                        />
                                                        <div className={item.date ? "date-top" : ''}>{item.date ? new Date(item?.date).toLocaleDateString("en-GB", { day: "numeric", month: "numeric", year: "numeric" }) : ''}</div>
                                                        <div className='overlay'>
                                                            <button className="activity-news-view-btn" onClick={() => handleActivityDetails(item.id)}>view</button>
                                                        </div>
                                                    </div>

                                                    <div className="card-body">
                                                        <h5 className="card-title" >{item.type}</h5>
                                                        <p>
                                                            <span style={{ color: '#272e38' }} className={item.day ? 'fw-bold' : 'd-none'}>DAY : </span>{" "}
                                                            {item.day ? item.day : ''} &nbsp;&nbsp;
                                                            <span style={{ color: '#272e38' }} className={item.time ? 'fw-bold' : 'd-none'}>TIME : </span>
                                                            {item.time ? item.time : ''}
                                                        </p>
                                                        <p className="text-muted" style={{textAlign:"justify"}}> {item.description ? (item?.description.replace(/(<([^>]+)>)/ig, '')).slice(0, 70) : ""}
                                                            {item?.description.length > 70 && (
                                                                <span style={{ fontSize: '12px', color: '#f16f23', cursor: 'pointer' }} onClick={() => handleActivityDetails(item.id)}>
                                                                    {item?.description.length > 70 && (
                                                                        <span> ...more</span>
                                                                    )}</span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div >
                            ) : (
                                <h2  >Data Not Found!{eventnewsactivitiesData}</h2>
                            )}
                        </div>
                    </div>
                    <FooterPage />
                </>
            )}
        </div>
    )
}

export default Activities
