import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FooterPage from '../../Footer/FooterPage';
import Header from '../../Header/Header';
import fetchData from "../../../common/fetchData";
import { get_rooms_category } from "../../../common/serverUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';


const Room = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [roomsData, setRoomsData] = useState();
    const [sliderMain, setMainSlider] = useState(null)
    const [sliderNav, setNavSlider] = useState(null)

    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    var current_date = yyyy + "-" + mm + "-" + dd;
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        getRooms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRooms = async () => {
        const rooms = await fetchData(
            "post",
            get_rooms_category, "",
        );

        if (rooms.code === "1") {

            setRoomsData(rooms.data);
        }
        else {
            console.log("not found");
        }
    };

    const handleRoomBooking = async (id) => {
        navigate(`/roombooking/${id}`);
    };

    // product images slider
    const sliderMainSetting = {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider-nav'
    };
    const sliderNavSetting = {
        // slidesToShow: 3	,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        arrows: true,
        dots: true,
        focusOnSelect: true
    };

    return (
        <div>
            <Header />
            {loading ? (
                <div className="loader-container" >
                    <div className="loader-icon">
                        <img src='/assets/img/logo2.png' alt="" />
                        <img src="/assets/img/loading5.gif" alt="" />
                    </div>
                </div>
            ) : (
                <>
                    <section className="innerPageHeader">
                        <div className="container" >
                            <div className='px-5'>
                                <h2 className='fs-white m-0 font'>Rooms</h2>
                                <ol className="breadcrumb fs-6">
                                    <li className="breadcrumb-item">
                                        <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                                            <span className='backpath'>Home </span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Rooms</li>
                                </ol>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            {roomsData && roomsData?.length > 0 ? (
                                <div>
                                    <h1 className='text-center'>Book Your Room</h1>
                                    <div className='room-details'>
                                        {roomsData?.map((item, index) => (
                                            <div className="row room-space" style={{ display: "flex!important" }}>
                                                {/* {this.renderArrows()} */}
                                                <div className='col-xl-6 col-lg-6 col-md-8 col-sm-12'>
                                                    <Slider className="slider slider-for"  {...sliderMainSetting} asNavFor={sliderNav} focusOnSelect={true} dots={false} arrows={true} slidesToShow={item?.images.split(",").length}>
                                                        {
                                                            item?.images.split(",").map((item) => {
                                                                return (
                                                                    <>
                                                                        <div className='room-img'>
                                                                            <img src={"https://ghelasomnath.org/images/" + item} alt="ghelasomnath rooms" className="img-fluid" />
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </Slider>
                                                </div>
                                                <div className='col-xl-2 col-lg-2 col-md-4 col-sm-2'>
                                                    <Slider className="slider slider-nav" {...sliderNavSetting}
                                                        asNavFor={sliderMain} dots={false} arrows={true} ref={(slider) => setNavSlider(slider)} focusOnSelect={true} swipeToSlide={true} slidesToShow={item?.images.split(",").length}>
                                                        {
                                                            item?.images.split(",").map((item, index) => {
                                                                return (
                                                                    <div >
                                                                        {index <= 2 &&
                                                                            <img src={"https://ghelasomnath.org/images/" + item} alt="ghelasomnath rooms" className="img-fluid" />
                                                                        }</div>
                                                                )
                                                            })
                                                        }
                                                    </Slider>
                                                </div>
                                                <div className='room-detail col-xl-4 col-lg-4 col-md-12 col-sm-12' >
                                                    <h5>{item.name}</h5>
                                                    <p className='mt-1'> <strong>Price : </strong> ₹{item.price}</p>
                                                    <p style={{ marginTop: "10px" }}> <strong>Facilities  : </strong></p>
                                                    <ul className='row' style={{ margin: "0 0 0 10px " }}>
                                                        {
                                                            item.description.split(",").map((item) => {
                                                                return (
                                                                    <div className='col-lg-6 col-md-4 col-sm-6' style={{ listStyleType: 'square' }}>
                                                                        <li className='mt-0 ms-1' >
                                                                            {item.replace(/(<([^>]+)>)/ig, '')}
                                                                        </li>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    {/* {(current_date > "2023-09-15") ? ( */}
                                                    {(current_date > "2024-08-31") ? (
                                                        <div className='room-book mt-1'>
                                                            <button className="book-btn" style={{ color: "white", width: "30px", padding: "10px 70px 10px 30px" }} onClick={() => handleRoomBooking(item.id)}>Book</button>
                                                        </div>
                                                    ) : (
                                                        <div className='mt-1'>
                                                            <button disabled style={{ backgroundColor: "rgb(248, 203, 203)", border: "1px solid red" }}> <h6 style={{ color: "red" }}>All Rooms Booked</h6> </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <h2  >Data Not Found!{roomsData}</h2>
                            )}
                        </div>
                    </section >

                    <FooterPage />
                </>
            )}
        </div >
    )
}

export default Room
