import React, { useEffect, useState } from "react";
import './pooja.css';
import { useNavigate } from "react-router-dom";
import { get_puja } from "../../../common/serverUrl";
import fetchData from "../../../common/fetchData";

const Pooja = () => {
    const navigate = useNavigate();

    const [poojaData, setPoojaData] = useState();
    useEffect(() => {
        getpuja();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getpuja = async () => {
        const puja = await fetchData(
            "post",
            get_puja, "",
        );
        // console.log(puja);
        if (puja.code === "1") {
            // console.log(puja.data);
            setPoojaData(puja.data);
        }
        else {
            console.log("not found");
        }
    };


    const handlePoojaBooking = async (id) => {
        navigate(`/pujabooking/${id}`);
    };
    const handlePooja = async () => {
        navigate(`/puja`);
    };
    return (
        <>
            <div className="service">
                <h1 className="text-center" style={{ padding: "3vmax 0" }}>Puja</h1>
                {poojaData && poojaData?.length > 0 ? (
                    <div className="row">
                        {poojaData.map((item, index) =>
                            index <= 3 &&
                            <div className="col-lg-3 col-md-6 col-sm-6" style={{cursor:'pointer'}} onClick={() => handlePoojaBooking(item.id)}>
                                <div className="thumb">
                                    <img className="thumb-img img-1" src={"https://ghelasomnath.org/images/" + item?.image} onClick={() => handlePoojaBooking(item.id)} alt='' />
                                    <div className="thumb-gradient"></div>
                                    <button className='view-btn' onClick={() => handlePoojaBooking(item.id)} >Book</button>
                                    <div className="thumb-anim">
                                        <h4 className="thumb-title">{item?.name}</h4>
                                        <h6 className="pujaprice"><span style={{fontWeight:'600'}}>Price:</span> &#8377;{item?.price} </h6>
                                        <p className="thumb-description">
                                            <p className="pujatime"><span style={{fontWeight:'600'}}>Time:</span>  ⏱{item?.time}</p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="text-center" style={{ paddingTop: '2vmax', paddingBottom: '5vmax' }}>
                            <button className="common-btn" onClick={() => handlePooja()}>
                                View All puja
                            </button>
                        </div>
                    </div>
                ) : (
                    <h2  >Data Not Found!{poojaData}</h2>
                )}
            </div>
        </>
    )
}

export default Pooja
