import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import FooterPage from "../../Footer/FooterPage";
import Header from '../../Header/Header';

function UserPolicy() {
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
      }, []);
    return (
        <div>
            <Header />
            {loading ? (
                <div className="loader-container" >
                <div className="loader-icon">
                    <img src='/assets/img/logo2.png' alt="" />
                    <img src="/assets/img/loading5.gif" alt="" />
                </div>
            </div>
            ) : (
                <>
            <section className="innerPageHeader">
                <div className="container" >
                    <div className='px-5'>
                        <h2 className='fs-white m-0'>User Policy</h2>
                        <ol className="breadcrumb fs-6">
                            <li className="breadcrumb-item">
                                <Link to='/' className={`${location.pathname === "/" ? "btn-link" : ""}`}>
                                    <span className='backpath'>Home </span>
                                </Link>

                            </li>
                            <li className="breadcrumb-item active" aria-current="page">User Policy</li>
                        </ol>
                    </div>
                </div>
            </section>
            <div className="starting-space">
                <div className="container">
                    <h3>User Policy</h3>
                    <p className="mt-2" style={{textAlign:"justify"}}>
                        નોંઘ:-<br />
                        (૧) ૨૪ કલાકનો સમય ગમે તે સમયે આવે તો ચેક આઉટ ટાઇમ સવારના ૮:00 કલાકનો રહેશે.<br />
                        (ર) રૂમ ભાડે રાખનાર તમામ સભ્યોના ફોટા પ્રુફ સાથે લાવવાના રહેશે.
                    </p>
                </div>
            </div>
            <FooterPage />
            </>
            )}
        </div>
    )
}
export default UserPolicy